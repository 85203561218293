import { Flex, GridProps, SimpleGrid } from '@chakra-ui/react';
import Search from '../Search';

type Props = GridProps & {
  stats?: React.ReactNode;
  actions?: React.ReactNode;
  setSearch?: (value: string) => void;
};

const ActionBar = ({ actions, stats, setSearch, ...rest }: Props) => {
  return (
    <SimpleGrid
      gap="20px"
      columns={{
        base: 1,
        md: 2,
      }}
      {...rest}
    >
      <Flex gap="20px">
        {stats}

        <Flex
          gap="10px"
          ml="auto"
          display={{
            base: 'flex',
            md: 'none',
          }}
        >
          {actions}
        </Flex>
      </Flex>

      <Flex
        gap="20px"
        justify="flex-end"
        direction={{
          base: 'column-reverse',
          md: 'row',
        }}
      >
        {setSearch && (
          <Search
            w={{
              base: '100%',
              md: '400px',
            }}
            onChange={setSearch}
          />
        )}

        <Flex
          gap="20px"
          display={{
            base: 'none',
            md: 'flex',
          }}
          align="flex-end"
          justify="flex-end"
        >
          {actions}
        </Flex>
      </Flex>
    </SimpleGrid>
  );
};

export default ActionBar;
