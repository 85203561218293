import {
  Flex,
  Input,
  Button,
  Spinner,
  FormControl,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { sendCode } from '@/api/auth';
import useRequestState from '@/hooks/useRequestState';
import { validateEmail } from '@/utils/validation';
import { HSeparator } from '@/components/Separator';
import GoogleLoginButton from './GoogleButton';

type Props = {
  setStep: (step: LoginSteps) => void;
};

const SignInForm = ({ setStep }: Props) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext<LoginFormData>();

  const email = watch('email');

  const { trigger: sendCodeTrigger, loading } = useRequestState<'ok'>(
    () => sendCode(email),
    [email],
    { condition: false, onSuccess: () => setStep('code') },
  );

  const emailIsValid = validateEmail(email);

  return (
    <>
      <GoogleLoginButton />

      <Flex align="center" mb="25px">
        <HSeparator />

        <Text color="gray.400" mx="14px">
          {t('login.or')}
        </Text>

        <HSeparator />
      </Flex>

      <Flex direction="column">
        <FormControl isRequired isInvalid={!!errors.email}>
          <Input
            isRequired
            fontSize="sm"
            type="email"
            placeholder="mail@example.com"
            mb="20px"
            size="lg"
            disabled={loading}
            {...register('email')}
          />

          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <Button
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="100%"
          h="50"
          onClick={sendCodeTrigger}
          disabled={loading || !emailIsValid}
        >
          {loading ? <Spinner /> : t('login.send_code')}
        </Button>
      </Flex>
    </>
  );
};

export default SignInForm;
