import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import AuthFormlayout from './components/FormLayout';
import SignInForm from './components/SignIn';
import OTP from './components/OtpForm';
import { ROUTES } from '@/constants/routes';
import usePageInfo from '@/hooks/usePageInfo';
import { ORGANIZATION_DEFAULT_TRIAL_DAYS } from '@/constants/app';

const LoginPage = () => {
  usePageInfo({ title: 'pages.login' });

  const [step, setStep] = useState<LoginSteps>('email');

  const { t } = useTranslation();

  const methods = useForm<LoginFormData>({
    mode: 'onChange',
  });

  return (
    <>
      <AuthFormlayout
        title={t('login.title')}
        leftContent={{
          text: t('login.create_account_text', { days: ORGANIZATION_DEFAULT_TRIAL_DAYS }),
          link: {
            href: ROUTES.AUTH_REGISTER,
            text: t('login.create_account_link'),
          },
        }}
      >
        <FormProvider {...methods}>
          {step === 'email' && <SignInForm setStep={setStep} />}
          {step === 'code' && <OTP setStep={setStep} />}
        </FormProvider>
      </AuthFormlayout>
    </>
  );
};

export default LoginPage;
