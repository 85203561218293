import { CardBodyProps, IconButton } from '@chakra-ui/react';
import { t } from 'i18next';
import { FaEye } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import DataTable from '@/components/Table';
import { formatDate } from '@/utils/date';
import { ROUTES } from '@/constants/routes';

type Props = CardBodyProps & {
  data: Contract[];
  title: string;
};

const ContractSimpleTable = ({ data, title }: Props) => {
  const navigate = useNavigate();

  return (
    <DataTable<Contract, 'unit' | 'price' | 'client' | 'actions'>
      title={title}
      data={data || []}
      columns={[
        {
          id: 'client',
          header: t('contracts.client'),
          accessor: 'data.client',
          cell: ({ data: { client } }) => (
            <>
              {client.name} {client.lastName}
            </>
          ),
        },
        {
          id: 'unit',
          header: t('contracts.unit'),
          accessor: 'data.unit.name',
        },
        {
          id: 'price',
          header: t('contracts.price'),
          accessor: 'data.unit.price',
        },
        {
          id: 'startDate',
          header: t('contracts.start_date'),
          accessor: 'startDate',
          cell: ({ startDate }) => <>{formatDate(startDate)}</>,
        },
        {
          id: 'endDate',
          header: t('contracts.end_date'),
          accessor: 'endDate',
          cell: ({ endDate }) => <>{formatDate(endDate)}</>,
        },
        {
          id: 'actions',
          header: t('contracts.actions'),
          accessor: 'id',
          center: true,
          cell: ({ _id }) => (
            <>
              <IconButton
                aria-label="View"
                size="sm"
                icon={<FaEye />}
                onClick={() => navigate(`${ROUTES.CONTRACTS}/${_id}`)}
              />
            </>
          ),
        },
      ]}
    />
  );
};

export default ContractSimpleTable;
