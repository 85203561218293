import { useNavigate } from 'react-router-dom';
import { Card, Flex, Heading, Link, Text, useColorModeValue } from '@chakra-ui/react';

import { Logo } from '@/components/Icons/Logo';
import { VSeparator } from '@/components/Separator';

type Props = {
  title: string;
  children: React.ReactNode;
  leftContent?: {
    text: string;
    link: {
      href: string;
      text: string;
    };
  };
};

const AuthFormlayout = ({ children, title, leftContent }: Props) => {
  const color = useColorModeValue('brand.600', 'gray.100');
  const navigate = useNavigate();

  return (
    <>
      <Flex
        justify="center"
        align="center"
        w="100%"
        mb="20px"
        display={{ base: 'flex', md: 'none' }}
      >
        <Logo width="100%" height="55px" fill={color} />
      </Flex>

      <Card p={{ base: '20px', md: '40px' }} w="100%">
        <Flex
          gap={{ base: '20px', md: '40px' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Flex
            direction="column"
            align="center"
            justify="center"
            w="300px"
            pb={{ base: 0, md: '20px' }}
            display={{ base: 'none', md: 'flex' }}
          >
            <Flex flex={1} justify="center" align="center" w="100%">
              <Logo width="100%" height="65px" fill={color} />
            </Flex>

            {leftContent && (
              <Flex direction="column" align="center">
                <Text fontSize="sm">
                  {leftContent.text}{' '}
                  <Link onClick={() => navigate(leftContent.link.href)}>
                    {leftContent.link.text}
                  </Link>
                </Text>
              </Flex>
            )}
          </Flex>

          <Flex display={{ base: 'none', md: 'flex' }}>
            <VSeparator />
          </Flex>

          <Flex direction="column" align="center" flex={1} pb={{ base: 0, md: '20px' }}>
            <Flex
              me="auto"
              mb="20px"
              mt={{ base: '-10px', md: '0' }}
              w="100%"
              justify="center"
            >
              <Heading>{title}</Heading>
            </Flex>

            <Flex direction="column" w="100%" me="auto">
              {children}
            </Flex>
          </Flex>
        </Flex>
      </Card>

      {leftContent && (
        <Flex justify="center" mt="20px" display={{ base: 'flex', md: 'none' }}>
          <Link onClick={() => navigate(leftContent.link.href)}>
            {leftContent.link.text}
          </Link>
        </Flex>
      )}
    </>
  );
};

export default AuthFormlayout;
