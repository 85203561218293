import { useLocation } from 'react-router-dom';

import { ROUTES } from '@/constants/routes';
import { useMemo } from 'react';

type AvoidProps = {
  avoidRoutes: valueof<typeof ROUTES>[];
  avoidComponent?: React.ReactNode;
  children: React.ReactNode;
};

export const AvoidRouteConditionRender = ({
  avoidRoutes,
  avoidComponent,
  children,
}: AvoidProps) => {
  const { pathname } = useLocation();

  const shouldRender = useMemo(() => {
    return !avoidRoutes.includes(pathname as valueof<typeof ROUTES>);
  }, [pathname, avoidRoutes]);

  if (!shouldRender) {
    return avoidComponent ? <>{avoidComponent}</> : null;
  }

  return <>{children}</>;
};
