import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Flex, HStack, IconButton } from '@chakra-ui/react';
import { FaPencilAlt, FaPeopleCarry, FaTrash } from 'react-icons/fa';
import { IoAdd, IoReload } from 'react-icons/io5';

import DataTable from '@/components/Table';
import { getPropertyClient, deleteClient } from '@/api/client';
import Pagination from '@/components/Pagination';
import usePageInfo from '@/hooks/usePageInfo';
import usePagination from '@/hooks/usePagination';
import useRequestState from '@/hooks/useRequestState';
import ActionButton from '@/components/ActionButton';
import AppStore from '@/stores/app';
import { formatDate } from '@/utils/date';
import ClientForm from './components/form';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import ActionBar from '@/components/ActionBar';

const ClientsPage = () => {
  usePageInfo({ title: 'pages.clients' });

  const [toDelete, setTodelete] = useState<Client | null>(null);
  const [selected, setSelected] = useState<Client | null | undefined>(undefined);

  const { t } = useTranslation();
  const { selectedProperty } = AppStore;

  const handleDialogToggle = useCallback((data?: Client | null) => {
    setTodelete(data ?? null);
  }, []);

  const {
    page,
    limit,
    sortBy,
    order,
    search,
    setPage,
    toggleOrder,
    setLimit,
    setSearch,
  } = usePagination<Client>({
    initialSortBy: 'name',
    initialOrder: 'asc',
  });

  const { data, trigger: dataTrigger } = useRequestState<PaginateResult<Client>>(
    () => getPropertyClient({ page, limit, sortBy, order, search }),
    [page, limit, sortBy, order, search, selectedProperty],
  );

  const onDeleteSuccess = useCallback(() => {
    dataTrigger();
    handleDialogToggle(null);
  }, [dataTrigger, handleDialogToggle]);

  const { trigger: deleteTrigger } = useRequestState<'ok'>(
    () => deleteClient(toDelete?._id!),
    [toDelete],
    { condition: false, onSuccess: onDeleteSuccess },
  );

  return (
    <>
      <Flex direction="column" h="100%">
        <ActionBar
          setSearch={setSearch}
          actions={
            <>
              <ActionButton mini icon={IoAdd} onClick={() => setSelected(null)} />
              <ActionButton mini icon={IoReload} onClick={dataTrigger} />
            </>
          }
          stats={
            <ActionButton
              mini
              hoverable={false}
              name={t('clients.total')}
              value={`${data?.totalDocs ?? '-'}`}
              icon={FaPeopleCarry}
            />
          }
        />

        <DataTable<Client>
          mt="20px"
          sortBy={sortBy}
          order={order}
          onSort={toggleOrder}
          data={data?.docs || []}
          columns={[
            {
              id: 'name',
              header: t('clients.name'),
              accessor: 'name',
              isSortable: true,
            },
            {
              id: 'lastName',
              header: t('clients.last_name'),
              accessor: 'lastName',
              isSortable: true,
            },
            {
              id: 'email',
              header: t('clients.email'),
              accessor: 'email',
              isSortable: true,
            },
            {
              id: 'language',
              header: t('clients.language'),
              accessor: 'language',
              cell: (client) => <>{t(`languages.${client.language}`)}</>,
            },
            {
              id: 'phone',
              header: t('clients.phone'),
              accessor: 'phone',
              isSortable: true,
            },
            {
              id: 'dob',
              header: t('clients.dob'),
              accessor: 'dob',
              isSortable: true,
              cell: (client) => <>{formatDate(client.dob)}</>,
            },
            {
              id: 'telegram',
              header: t('clients.telegram'),
              accessor: 'telegram',
              isSortable: true,
            },
            {
              id: 'whatsapp',
              header: t('clients.whatsapp'),
              accessor: 'whatsapp',
              isSortable: true,
            },
            {
              id: 'actions',
              header: t('properties.actions'),
              accessor: null,
              center: true,
              cell: (data: Client) => (
                <HStack justify="center">
                  <IconButton
                    aria-label="Update"
                    size="sm"
                    icon={<FaPencilAlt />}
                    onClick={() => setSelected(data)}
                  />

                  <IconButton
                    aria-label="Delete"
                    size="sm"
                    icon={<FaTrash />}
                    onClick={() => handleDialogToggle(data)}
                  />
                </HStack>
              ),
            },
          ]}
          pagination={
            <Pagination
              {...data}
              onPageSizeChange={setLimit}
              onPageChange={setPage}
              limit={limit}
            />
          }
        />
      </Flex>

      <ClientForm data={selected} setSelected={setSelected} onSubmit={dataTrigger} />

      <ConfirmDialog
        isOpen={!!toDelete}
        title={t('clients.delete_confirmation_title')}
        body={t('clients.delete_confirmation_body', {
          name: `${toDelete?.name} ${toDelete?.lastName}`,
        })}
        onConfirm={deleteTrigger}
        onCancel={() => handleDialogToggle()}
      />
    </>
  );
};

export default observer(ClientsPage);
