import { formatToTwoDecimals } from '@/utils/format';
import { request } from '@/utils/request';

export const getInvoices = async (
  query: PaginationRequestType,
  contract?: Contract['_id'],
) =>
  request<PaginateResult<Invoice>>({
    url: '/invoice',
    query: { ...query, contract },
  });

export const createInvoice = async ({
  items = [],
  amount,
  description,
  contract,
  type,
  comments,
}: InvoiceFormData) =>
  request<Invoice>({
    url: '/invoice',
    method: 'POST',
    body: {
      amount: formatToTwoDecimals(+amount),
      type,
      comments,
      contract: contract?._id,
      items: items.length ? items : undefined,
      description: items.length ? undefined : description,
    },
  });

export const removeInvoice = async (id: string) =>
  request<'ok'>({
    url: '/invoice',
    method: 'DELETE',
    param: id,
  });

export const sendInvoice = async (id: string) =>
  request<'ok'>({
    url: '/invoice/send',
    param: id,
  });

export const refundInvoice = async (id: string) =>
  request<'ok'>({
    url: '/invoice/refund',
    method: 'POST',
    body: { id },
  });

export const closeManually = async (id: string, data: InvoiceCloseManuallyData) =>
  request<'ok'>({
    url: '/invoice/close-manually',
    method: 'POST',
    body: data,
    param: id,
  });
