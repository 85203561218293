import { Card, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaDollarSign } from 'react-icons/fa';
import { MdPercent } from 'react-icons/md';
import { TbActivity } from 'react-icons/tb';

import { getUnit } from '@/api/unit';
import useRequestState from '@/hooks/useRequestState';
import usePageInfo from '@/hooks/usePageInfo';
import ActionButton from '@/components/ActionButton';
import ContractSimpleTable from '@/views/Contracts/components/ContractTable';
import ExpensesTable from '@/views/Expenses/components/ExpensesTable';
import { getContracts } from '@/api/contract';
import {
  CONTRACT_STATUS,
  DEFAULT_PAGINATION_OPTIONS,
  FILE_COLLECTIONS,
  FILE_LIMITS,
} from '@/constants/app';
import FilesTable from '@/views/Files/components/FilesTable';

const UnitDetails = () => {
  usePageInfo({ title: 'pages.unit_details' });

  const { id } = useParams();
  const { t } = useTranslation();

  const { data } = useRequestState<Unit>(() => getUnit(id!), [id], {
    condition: !!id,
  });

  const { data: contracts } = useRequestState<PaginateResult<Contract>>(
    () =>
      getContracts(DEFAULT_PAGINATION_OPTIONS, {
        unit: id,
        status: CONTRACT_STATUS.ACTIVE,
      }),
    [id],
    { condition: !!id },
  );

  return (
    <>
      {(data?.name || data?.comments) && (
        <Card width="fit-content" mb="20px">
          <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="bold">
            {data?.name}
          </Text>

          {data?.comments && (
            <Text fontSize={{ base: 'sm', md: 'md' }}>
              {t('unit_details.comments')}: {data?.comments}
            </Text>
          )}
        </Card>
      )}

      <Flex gap={5} direction="column" mb="20px">
        <SimpleGrid columns={{ base: 2, md: 4, lg: 4, xl: 5, '2xl': 7 }} gap="20px">
          <ActionButton
            mini
            hoverable={false}
            name={t('unit_details.status')}
            value={data?.isActive ? t('unit_details.active') : t('unit_details.inactive')}
            icon={TbActivity}
          />

          <ActionButton
            mini
            hoverable={false}
            name={t('unit_details.price')}
            value={data?.price.toString()}
            icon={FaDollarSign}
          />

          <ActionButton
            mini
            hoverable={false}
            name={t('unit_details.deposit')}
            value={data?.deposit.toString()}
            icon={FaDollarSign}
          />

          <ActionButton
            mini
            hoverable={false}
            name={t('unit_details.penalty_percent')}
            value={data?.latePaymentPenaltyPercent.toString() + '%'}
            icon={MdPercent}
          />
        </SimpleGrid>
      </Flex>

      <Flex direction="column" flex={1}>
        <Flex maxH="500px" direction="column" mb="20px">
          <ContractSimpleTable
            data={contracts?.docs ?? []}
            title={t('unit_details.active_contracts')}
            pt="10px"
          />
        </Flex>

        <Flex maxH="500px" direction="column">
          <FilesTable
            miniActions
            title={t('files.files')}
            maxH="350px"
            folder={id}
            group={FILE_COLLECTIONS.UNITS}
            fileNumberLimit={FILE_LIMITS[FILE_COLLECTIONS.UNITS]}
            layout={{
              add: true,
              reload: true,
            }}
          />
        </Flex>

        <Flex direction="column" flex={1}>
          <ExpensesTable
            miniActions
            byUnit
            unit={data}
            title={t('unit_details.expenses')}
            layout={{
              add: true,
              reload: true,
              search: false,
              total: false,
            }}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default UnitDetails;
