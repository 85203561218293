import { useCallback, useState } from 'react';
import { SimpleGrid, Flex, Icon, IconButton, Spinner, Text } from '@chakra-ui/react';
import { BsStripe } from 'react-icons/bs';
import { FaSync, FaPlusCircle } from 'react-icons/fa';
import { MdVerified } from 'react-icons/md';
import { TbTrash } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';

import { removeStripeAcc, stripeVerifyList } from '@/api/stripe';
import useRequestState from '@/hooks/useRequestState';
import AddStripeAccount from './AddStripeAccount';
import DataTable from '@/components/Table';

const StripeList = () => {
  const [stripePopupOpen, setStripePopupOpen] = useState(false);
  const [data, setData] = useState<StripeAccountList>([]);

  const { t } = useTranslation();

  const { trigger: stripeRefresh, loading: verifyLoading } =
    useRequestState<StripeAccountList>(() => stripeVerifyList(), [], {
      condition: false,
      onSuccess: setData,
    });

  const { trigger: listTrigger, loading: listLoading } =
    useRequestState<StripeAccountList>(() => stripeVerifyList(), [], {
      onSuccess: setData,
    });

  const { trigger: removeTrigger, loading: removeLoading } = useRequestState<'ok'>(
    (id) => removeStripeAcc(id as string),
    [],
    {
      condition: false,
      onSuccess: listTrigger,
    },
  );

  const handleClose = useCallback(() => {
    setStripePopupOpen(false);

    listTrigger();
  }, [setStripePopupOpen, listTrigger]);

  const loading = listLoading || verifyLoading || removeLoading;

  return (
    <>
      <SimpleGrid columns={{ base: 1, lg: 1, xl: 1, '3xl': 2 }}>
        <Flex direction="column">
          <Flex direction="row" align="center" mb="15px" pl="15px">
            <Icon as={BsStripe} mr="15px" fontSize="2xl" />

            <Text fontWeight="bold" fontSize="2xl">
              {t('settings.stripe.stripe_accounts')}
            </Text>

            <Flex ml="auto" direction="row" gap="10px">
              <IconButton
                ml="auto"
                disabled={loading}
                aria-label="Refresh Stripe Accounts"
                icon={verifyLoading ? <Spinner size="sm" /> : <Icon as={FaSync} />}
                onClick={stripeRefresh}
              />

              <IconButton
                ml="auto"
                disabled={loading}
                aria-label="Add Stripe Account"
                icon={<Icon as={FaPlusCircle} />}
                onClick={() => setStripePopupOpen(true)}
              />
            </Flex>
          </Flex>

          <DataTable<StripeAccount, 'actions'>
            data={data || []}
            columns={[
              {
                id: 'name',
                header: t('settings.stripe.name'),
                accessor: 'name',
              },
              {
                id: 'country',
                header: t('settings.stripe.country'),
                accessor: 'country',
              },
              {
                id: 'currency',
                header: t('settings.stripe.currency'),
                accessor: 'currency',
              },
              {
                id: 'email',
                header: t('settings.stripe.email'),
                accessor: 'email',
              },
              {
                id: 'verified',
                header: t('settings.stripe.verified'),
                accessor: 'verified',
                center: true,
                cell: ({ verified }) => (
                  <Icon as={MdVerified} color={verified ? 'green.400' : 'red.400'} />
                ),
              },
              {
                id: 'actions',
                header: t('settings.stripe.actions'),
                accessor: 'id',
                center: true,
                cell: ({ _id }) => (
                  <IconButton
                    aria-label="remove"
                    onClick={() => removeTrigger(_id)}
                    icon={<TbTrash />}
                    disabled={loading}
                  />
                ),
              },
            ]}
          />
        </Flex>
      </SimpleGrid>

      <AddStripeAccount onClose={handleClose} isOpen={stripePopupOpen} />
    </>
  );
};

export default StripeList;
