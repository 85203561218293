import { request } from '@/utils/request';

export const downloadFile = async (_id: string) =>
  request<string>({
    url: '/file',
    method: 'GET',
    param: _id,
  });

export const getFileList = async (
  query: PaginationRequestType,
  filters?: IFilesFilters,
) =>
  request<PaginateResult<IFile>>({
    url: '/file/list',
    method: 'GET',
    query: {
      ...query,
      ...filters,
    },
  });

export const deleteFile = async (_id: IFile['_id']) =>
  request<'ok'>({
    url: '/file',
    method: 'DELETE',
    param: _id,
  });

export const uploadFile = async (body: FormData) =>
  request<'ok'>({
    url: '/file',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    body,
    config: { skipStringify: true },
  });
