import usePageInfo from '@/hooks/usePageInfo';
import FilesTable from './components/FilesTable';

const FilesPage = () => {
  usePageInfo({ title: 'pages.files' });

  return (
    <FilesTable
      layout={{
        search: true,
        total: true,
        reload: true,
        add: false,
      }}
    />
  );
};

export default FilesPage;
