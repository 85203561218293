import { useMemo } from 'react';
import {
  Button,
  Card,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdPercent } from 'react-icons/md';
import {
  FaCalendarAlt,
  FaCalendarCheck,
  FaClock,
  FaDollarSign,
  FaUnlockAlt,
} from 'react-icons/fa';
import { TbInvoice, TbStatusChange } from 'react-icons/tb';

import { changeStatus, contractStats, getContract } from '@/api/contract';
import useRequestState from '@/hooks/useRequestState';
import usePageInfo from '@/hooks/usePageInfo';
import InvoiceTable from '@/views/Invoices/components/InvoiceTable';
import ActionButton from '@/components/ActionButton';
import { formatDate } from '@/utils/date';
import ProfileCard from '@/components/ProfileCard';
import PieCard from '@/components/PieCard';
import { getInvoiceChartData, getInvoicesLegendChart } from '@/utils/invoices';
import { getNextStatus } from '@/utils/contracts';
import { ChevronDownIcon } from '@chakra-ui/icons';
import FilesTable from '@/views/Files/components/FilesTable';
import { CONTRACT_STATUS, FILE_COLLECTIONS, FILE_LIMITS } from '@/constants/app';

const ContractDetails = () => {
  usePageInfo({ title: 'pages.contract_details' });

  const { id } = useParams();
  const { t } = useTranslation();

  const { data, trigger: dataTrigger } = useRequestState<Contract>(
    () => getContract(id!),
    [],
    { condition: !!id },
  );

  const { trigger: changeStatusTrigger } = useRequestState<Contract, any>(
    (data) => changeStatus(data!),
    [],
    { onSuccess: dataTrigger, condition: false },
  );

  const { data: statsData } = useRequestState<InvoicesStats>(
    () => contractStats(id!),
    [],
    { condition: !!id },
  );

  const handleSelectStatus = (status: ContractStatus) => {
    if (!data || data?.status === status) return;

    changeStatusTrigger({ status, id });
  };

  const client = data?.data.client;

  const chartData = useMemo(() => {
    return getInvoiceChartData(statsData, t);
  }, [statsData, t]);

  const unpaidVsPaidLegend = useMemo(() => {
    return getInvoicesLegendChart(statsData, t);
  }, [statsData, t]);

  const canAddData = data?.status !== 'CLOSED' && data?.status !== 'DRAFT';
  const isClosed = data?.status === CONTRACT_STATUS.CLOSED;

  return (
    <>
      <Flex gap={5} direction="column">
        <SimpleGrid
          ml="auto"
          gap={{ base: 2, md: 5 }}
          w="100%"
          columns={{
            base: 1,
            md: 2,
          }}
        >
          <Flex maxW="max-content" justify="end" align="end">
            <Menu>
              <MenuButton as={Button} leftIcon={<TbStatusChange />} disabled={isClosed}>
                {t(`contracts.statuses.${data?.status}`)}

                {!isClosed && <ChevronDownIcon ml="5px" />}
              </MenuButton>

              {!!getNextStatus(data?.status! ?? null).length && (
                <MenuList>
                  {getNextStatus(data?.status! ?? null).map((key) => (
                    <MenuItem key={key} onClick={() => handleSelectStatus(key)}>
                      {t(`contracts.statuses.${data?.status}`)}
                      {' → '}
                      {t(`contracts.statuses.${key}`)}
                    </MenuItem>
                  ))}
                </MenuList>
              )}
            </Menu>
          </Flex>

          {data?.comments && (
            <Flex flex={1} w="100%" justify="end">
              <Text fontWeight="bold" mr={2}>
                {t('contract_details.comments')}:
              </Text>

              <Text maxW="100%" isTruncated opacity="0.75">
                {data?.comments}
              </Text>
            </Flex>
          )}
        </SimpleGrid>

        <SimpleGrid columns={{ base: 2, md: 4, lg: 4, xl: 5, '2xl': 7 }} gap="20px">
          <ActionButton
            mini
            hoverable={false}
            name={t('contract_details.start_date')}
            value={formatDate(data?.startDate)}
            icon={FaCalendarAlt}
          />

          <ActionButton
            mini
            hoverable={false}
            name={t('contract_details.end_date')}
            value={formatDate(data?.endDate)}
            icon={FaCalendarAlt}
          />

          <ActionButton
            mini
            hoverable={false}
            name={t('contract_details.sign_date')}
            value={formatDate(data?.signedAt)}
            icon={FaCalendarCheck}
          />

          <ActionButton
            mini
            hoverable={false}
            name={t('contract_details.price')}
            value={data?.data.unit.price.toString()}
            icon={FaDollarSign}
          />

          <ActionButton
            mini
            hoverable={false}
            name={t('contract_details.deposit')}
            value={data?.data.unit.deposit.toString()}
            icon={FaUnlockAlt}
          />

          <ActionButton
            mini
            hoverable={false}
            name={t('contract_details.late_payment_penalty_percent')}
            value={data?.data.unit.latePaymentPenaltyPercent.toString() + '%'}
            icon={MdPercent}
          />

          <ActionButton
            mini
            hoverable={false}
            name={t('contract_details.initial_invoice')}
            value={data?.skipInitialInvoice ? t('common.no') : t('common.yes')}
            icon={TbInvoice}
          />

          <ActionButton
            mini
            hoverable={false}
            name={t('contract_details.deposit_charge')}
            value={!data?.withDepositInitialCharge ? t('common.no') : t('common.yes')}
            icon={TbInvoice}
          />

          <ActionButton
            mini
            hoverable={false}
            name={t('contract_details.payment_circle')}
            value={t(`properties.payment_circles.${data?.data.property.paymentCircle}`)}
            icon={FaClock}
          />
        </SimpleGrid>
      </Flex>

      <SimpleGrid my={5} columns={{ base: 1, lg: 2 }} gap={5}>
        <Card>
          <ProfileCard
            title={`${client?.name} ${client?.lastName}`}
            subtitle={formatDate(client?.dob)}
            data={[
              { key: t('contract_details.phone'), value: client?.phone || '' },
              { key: t('contract_details.email'), value: client?.email || '' },
              {
                key: t('contract_details.document'),
                value: client?.document.number || '',
              },
            ]}
          />
        </Card>

        <Card>
          <PieCard
            title={t('contract_details.invoices')}
            data={chartData}
            legend={unpaidVsPaidLegend}
          />
        </Card>
      </SimpleGrid>

      <Flex direction="column" flex={1}>
        <Flex direction="column">
          <InvoiceTable
            miniActions
            byContract
            contract={data}
            title={t('contract_details.invoices')}
            layout={{
              add: canAddData,
              reload: true,
              filters: true,
            }}
          />
        </Flex>

        <Flex direction="column" flex={1}>
          <FilesTable
            miniActions
            title={t('files.files')}
            folder={id}
            fileNumberLimit={FILE_LIMITS[FILE_COLLECTIONS.CONTRACTS]}
            group={FILE_COLLECTIONS.CONTRACTS}
            layout={{
              add: canAddData,
              reload: true,
            }}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default ContractDetails;
