export const ENV = process.env.REACT_APP_NODE_ENV || 'development';
export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

export const GOOGLE_CLIENT_ID =
  '647434470569-e802rqp0779bjtq14podicc6bq6o959l.apps.googleusercontent.com';

export const GET_HELP_EMAIL = 'admin@leezly.com';

export const OTP_LENGTH = 4;

export const MIN_PAYMENT_DAY = 1;
export const MAX_PAYMENT_DAY = 28;

export const SELECTED_PROPERTY_HEADER = 'x-selected-property';

export enum CURRENCY {
  USD = 'USD',
  EUR = 'EUR',
  PLN = 'PLN',
  UAH = 'UAH',
}

export const CURRENCY_SYMBOLS = {
  [CURRENCY.USD]: '$',
  [CURRENCY.EUR]: '€',
  [CURRENCY.PLN]: 'zł',
  [CURRENCY.UAH]: '₴',
};

export enum COUNTRIES {
  PL = 'PL',
  UA = 'UA',
  US = 'US',
}

export const SUPPORTED_LANGUAGES = {
  EN: 'en',
  RU: 'ru',
  UK: 'uk',
  PL: 'pl',
} as const;

export const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES.EN;

export const EXPIRATION_DAYS_WARNING = 30;

export const ORGANIZATION_DEFAULT_TRIAL_DAYS = 7;
export const ORGANIZATION_DUE_PERIOD_DAYS = 7;

export enum SUBSCRIPTION_INTERVALS {
  MONTH = 'month',
  YEAR = 'year',
}

export enum CONTRACT_STATUS {
  DRAFT = 'DRAFT',
  PENDING_SIGNATURE = 'PENDING_SIGNATURE',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  OVERDUE = 'OVERDUE',
  CLOSED = 'CLOSED',
}

export enum INVOICE_STATUS {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  PAID = 'PAID',
  OVERDUE = 'OVERDUE',
  REFUNDED = 'REFUNDED',
  CANCELLED = 'CANCELLED',
}

export enum INVOICE_TYPE {
  REGULAR = 'REGULAR',
  CUSTOM = 'CUSTOM',
}

export enum INVOICE_ITEM_TYPE {
  DEPOSIT = 'DEPOSIT',
  REGULAR = 'REGULAR',
  OVERDUE_PENALTY = 'OVERDUE_PENALTY',
  CUSTOM = 'CUSTOM',
}

export const UPAID_INVOICE_STATUSES: InvoiceStatus[] = [
  INVOICE_STATUS.PENDING,
  INVOICE_STATUS.OVERDUE,
];

export const NEXT_STATUS_MAP: {
  [key in CONTRACT_STATUS]: (keyof typeof CONTRACT_STATUS)[];
} = {
  [CONTRACT_STATUS.DRAFT]: [CONTRACT_STATUS.PENDING_SIGNATURE],
  [CONTRACT_STATUS.PENDING_SIGNATURE]: [CONTRACT_STATUS.ACTIVE, CONTRACT_STATUS.CLOSED],
  [CONTRACT_STATUS.ACTIVE]: [CONTRACT_STATUS.PAUSED, CONTRACT_STATUS.CLOSED],
  [CONTRACT_STATUS.PAUSED]: [CONTRACT_STATUS.ACTIVE, CONTRACT_STATUS.CLOSED],
  [CONTRACT_STATUS.OVERDUE]: [CONTRACT_STATUS.CLOSED],
  [CONTRACT_STATUS.CLOSED]: [],
};

export enum PAYMENT_METHOD {
  ONLINE = 'ONLINE',
  BANK_TRANSFER = 'BANK_TRANSFER',
  TERMINAL = 'TERMINAL',
  CRYPTO = 'CRYPTO',
  CASH = 'CASH',
  MOBILE_TRANSFER = 'MOBILE_TRANSFER',
}

export enum DOCUMENT_TYPE {
  PASSPORT = 'PASSPORT',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  ID_CARD = 'ID_CARD',
}

export enum PAYMENT_SHIFT_DAY {
  MIN = -15,
  MAX = 15,
  DEFAULT = 0,
}

export enum PAYMENT_SHIFT_WEEK_DAY {
  MIN = 0,
  MAX = 6,
  DEFAULT = 0,
}

export enum PAYMENT_CIRCLE {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
}

export const INVOICE_EXPIRATION_DAYS = 7;

export enum COMPLAIN_STATUS {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  REJECTED = 'REJECTED',
}

export enum DATE_FILTERS {
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  LAST_YEAR = 'LAST_YEAR',
}

export enum TASK_STATUS {
  TODO = 'TODO',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export enum TASK_TYPE {
  TASK = 'TASK',
  BUG = 'BUG',
}

export enum ADMIN_ROLE {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export const PAGINATION_SIZE_OPTIONS: PaginationLimits[] = [10, 20, 50, 100];
export const PAGINATION_DEFAULT_LIMIT = PAGINATION_SIZE_OPTIONS[0];

export const DEFAULT_PAGINATION_OPTIONS: PaginationRequestType = {
  page: 1,
  limit: PAGINATION_DEFAULT_LIMIT,
  sortBy: 'name',
  order: 'asc',
} as const;

export enum EXPENSE_STATUS {
  PLANNED = 'PLANNED',
  PAID = 'PAID',
}

export const WEEK_DAYS_NUMBER = Array.from({ length: 7 }, (_, i) => i);

export enum FILE_COLLECTIONS {
  UNITS = 'UNITS',
  CONTRACTS = 'CONTRACTS',
}

export const ACCEPT_FILE_TYPES = {
  IMAGES: {
    'image/jpeg': ['.jpeg'],
    'image/jpg': ['.jpg'],
    'image/png': ['.png'],
  },
  EXCEL: {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.ms-excel': ['.xls'],
  },
  DOC: {
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  },
  CSV: {
    'text/csv': ['.csv'],
  },
  PDF: {
    'application/pdf': ['.pdf'],
  },
};

export const FILE_SIZE_LIMIT = 20 * 1024 * 1024; // 20MB

export const FILE_LIMITS = {
  [FILE_COLLECTIONS.UNITS]: 10,
  [FILE_COLLECTIONS.CONTRACTS]: 10,
};

export const TELEGRAM_BOT_URL =
  process.env.REACT_APP_TELEGRAM_BOT_URL ?? 'https://t.me/leezly_bot';
