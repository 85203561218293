import { ENV } from '@/constants/app';
import * as Sentry from '@sentry/react';

(() => {
  if (ENV === 'development') return;

  Sentry.init({
    dsn: 'https://4e8abfebfed4d54adf6561786db5dcc2@o4508483725688832.ingest.de.sentry.io/4508483730341968',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^https:\/\/leezly\.com/,
      /^https:\/\/.*\.leezly\.com/,
      /^https:\/\/app-dev-hmcb\.onrender\.com/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
})();
