import Decimal from 'decimal.js';

import { CURRENCY_SYMBOLS } from '@/constants/app';

export const stringToBool = (value: string | boolean) => {
  if (typeof value === 'string') {
    return value === 'true';
  }

  return value === true;
};

export const formatToTwoDecimals = (value: number) =>
  Number(new Decimal(value).toFixed(2));

export const bitsToMegaBytes = (value: number) =>
  formatToTwoDecimals(value / 1024 / 1024);

export const getReadabeExtention = (type: string) => {
  let ext = type.split('/')[1];

  if (ext === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    ext = 'xlsx';
  } else if (ext === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
    ext = 'docx';
  } else if (ext === 'msword') {
    ext = 'doc';
  } else if (ext === 'vnd.ms-excel') {
    ext = 'xls';
  } else if (ext === 'jpeg') {
    ext = 'jpg';
  }

  return ext.toUpperCase();
};

export const hideEmail = (email: string) => {
  const [username, domain] = email.split('@');
  const hiddenUsername = `${username.slice(0, 3)}${'*'.repeat(username.length - 2)}`;

  return `${hiddenUsername}@${domain}`;
};

export const getCurrencySymbol = (curr: string) => {
  // @ts-ignore
  return CURRENCY_SYMBOLS[curr.toUpperCase()];
};
