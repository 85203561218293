import '@/assets/css/App.css';
import '@/utils/i18n';

import { ChakraProvider } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import '@/utils/sentry';
import theme from '@/theme';

import Router from './Router';
import { GOOGLE_CLIENT_ID } from './constants/app';

function App() {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <ChakraProvider theme={theme}>
        <Router />
      </ChakraProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
