import { useTranslation } from 'react-i18next';
import { Card, Flex, Text } from '@chakra-ui/react';

import { GET_HELP_EMAIL } from '@/constants/app';
import { Link } from 'react-router-dom';

const Error = () => {
  const { t } = useTranslation();

  return (
    <Flex flex={1} justify="center" align="center">
      <Card
        align="center"
        w="auto"
        p={{
          base: '20px',
          md: '40px',
        }}
      >
        <Flex justify="center" align="center" mb="20px">
          <Text fontSize="2xl">🤯</Text>
        </Flex>

        <Flex justify="center" align="center" direction="column" maxW="450px">
          <Text fontSize="xl" mb="10px" textAlign="center">
            {t('common.error_description')}
          </Text>

          <Text fontSize="xl" color="cyan">
            <Link to={`mailto:${GET_HELP_EMAIL}`}>{GET_HELP_EMAIL}</Link>
          </Text>
        </Flex>
      </Card>
    </Flex>
  );
};

export default Error;
