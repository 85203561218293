import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Badge, Flex, HStack, IconButton, Link, Text } from '@chakra-ui/react';
import { IoAdd, IoGridOutline, IoReload } from 'react-icons/io5';
import { FaEye, FaPencilAlt, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { BsFilter } from 'react-icons/bs';

import DataTable from '@/components/Table';
import { deleteUnit, getUnits } from '@/api/unit';
import Pagination from '@/components/Pagination';
import usePageInfo from '@/hooks/usePageInfo';
import usePagination from '@/hooks/usePagination';
import useRequestState from '@/hooks/useRequestState';
import ActionButton from '@/components/ActionButton';
import AppStore from '@/stores/app';
import UnitForm from './components/form';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import { formatDate } from '@/utils/date';
import ActionBar from '@/components/ActionBar';
import { ROUTES } from '@/constants/routes';
import useFilters from '@/hooks/useDataFilters';
import { isFiltered } from '@/utils/dataFilters';
import TableFilters from '@/components/TableFilters';

type Filters = { isActive: Unit['isActive'] };

const FILTERS: PaginationFilters<Filters> = {
  isActive: {
    title: 'units.status',
    values: [true, false].map((el) => ({
      value: el,
      selected: false,
      tKey: `units.statuses.${el}`,
    })),
  },
} as const;

const UnitsPage = () => {
  usePageInfo({ title: 'pages.units' });

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { selectedProperty, subscription } = AppStore;

  const [selectedUnit, setSelectedUnit] = useState<Unit | null | undefined>(undefined);
  const [toDelete, setTodelete] = useState<Unit | null>(null);

  const {
    page,
    limit,
    sortBy,
    order,
    search,
    setPage,
    toggleOrder,
    setLimit,
    setSearch,
  } = usePagination<Unit>({
    initialSortBy: 'name',
    initialOrder: 'asc',
  });

  const { isFiltersOpen, filters, setFiltersOpen, setFilter } = useFilters<Filters>({
    initialFilters: { isActive: [] },
  });

  const handleDialogToggle = useCallback((data?: Unit | null) => {
    setTodelete(data ?? null);
  }, []);

  const { data, trigger: dataTrigger } = useRequestState<PaginateResult<Unit>>(
    () => getUnits({ page, limit, sortBy, order, search, ...filters }),
    [page, limit, sortBy, order, selectedProperty, search, filters],
  );

  const onDeleteSuccess = useCallback(() => {
    dataTrigger();
    handleDialogToggle(null);
  }, [dataTrigger, handleDialogToggle]);

  const { trigger: deleteTrigger } = useRequestState<'ok'>(
    () => deleteUnit(toDelete?._id!),
    [toDelete],
    { condition: false, onSuccess: onDeleteSuccess },
  );

  const unitLimit = subscription?.limits.units ?? '0';

  return (
    <>
      <Flex direction="column" h="100%">
        <ActionBar
          setSearch={setSearch}
          stats={
            <ActionButton
              mini
              hoverable={false}
              name={t('units.total')}
              value={`${data?.totalDocs ?? '-'} / ${unitLimit}`}
              icon={IoGridOutline}
            />
          }
          actions={
            <>
              <ActionButton
                mini
                icon={BsFilter}
                onClick={() => setFiltersOpen(true)}
                redDot={isFiltered(filters)}
              />
              <ActionButton mini icon={IoAdd} onClick={() => setSelectedUnit(null)} />
              <ActionButton mini icon={IoReload} onClick={dataTrigger} />
            </>
          }
        />

        <DataTable<Unit>
          mt="20px"
          sortBy={sortBy}
          order={order}
          onSort={toggleOrder}
          data={data?.docs || []}
          columns={[
            {
              id: 'name',
              header: t('units.name'),
              accessor: 'name',
              isSortable: true,
              cell: (data: Unit) => (
                <Link onClick={() => navigate(`${ROUTES.UNITS}/${data._id}`)}>
                  {data.name}
                </Link>
              ),
            },
            {
              id: 'isActive',
              header: t('units.status'),
              accessor: 'isActive',
              cell: (unit: Unit) => (
                <Badge colorScheme={unit.isActive ? 'green' : 'red'}>
                  {t(`units.statuses.${unit.isActive}`)}
                </Badge>
              ),
            },
            {
              id: 'deposit',
              header: t('units.deposit'),
              accessor: 'deposit',
              isSortable: true,
            },
            {
              id: 'price',
              header: t('units.price'),
              accessor: 'price',
              isSortable: true,
            },
            {
              id: 'latePaymentPenaltyPercent',
              header: t('units.penalty_percent'),
              accessor: 'latePaymentPenaltyPercent',
              center: true,
              isSortable: true,
            },
            {
              id: 'comments',
              header: t('units.comments'),
              accessor: 'comments',
              maxW: '200px',
            },
            {
              id: 'createdAt',
              header: t('units.created_at'),
              accessor: 'createdAt',
              isSortable: true,
              cell: (unit: Unit) => <Text>{formatDate(unit.createdAt)}</Text>,
            },
            {
              id: 'actions',
              header: t('units.actions'),
              accessor: null,
              center: true,
              cell: (data: Unit) => (
                <HStack justify="center">
                  <IconButton
                    aria-label="View"
                    size="sm"
                    icon={<FaEye />}
                    onClick={() => navigate(`${ROUTES.UNITS}/${data._id}`)}
                  />

                  <IconButton
                    aria-label="Update"
                    size="sm"
                    icon={<FaPencilAlt />}
                    onClick={() => setSelectedUnit(data)}
                  />

                  <IconButton
                    aria-label="Delete"
                    size="sm"
                    icon={<FaTrash />}
                    onClick={() => handleDialogToggle(data)}
                  />
                </HStack>
              ),
            },
          ]}
          pagination={
            <Pagination
              {...data}
              onPageSizeChange={setLimit}
              onPageChange={setPage}
              limit={limit}
            />
          }
        />
      </Flex>

      <TableFilters<Filters>
        isOpen={isFiltersOpen}
        setFiltersOpen={setFiltersOpen}
        initialFilters={FILTERS}
        setFilter={setFilter}
      />

      <UnitForm
        data={selectedUnit}
        setSelected={setSelectedUnit}
        onSubmit={dataTrigger}
      />

      <ConfirmDialog
        isOpen={!!toDelete}
        title={t('units.delete_confirmation_title')}
        body={t('units.delete_confirmation_body', {
          name: `${toDelete?.name}`,
        })}
        onConfirm={deleteTrigger}
        onCancel={() => handleDialogToggle()}
      />
    </>
  );
};

export default observer(UnitsPage);
