import { useEffect, useState } from 'react';
import {
  Box,
  Input,
  Text,
  Badge,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { FaUser } from 'react-icons/fa';

import useRequestState from '@/hooks/useRequestState';
import { getContracts } from '@/api/contract';
import NoData from '@/components/NoData';
import { CONTRACT_STATUS } from '@/constants/app';

const ContractStep = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const requestCriteria = search.length >= 3;

  const {
    control,
    setValue,
    register,
    watch,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const value = watch('contract');

  useEffect(() => {
    clearErrors('contract');
  }, [clearErrors, search]);

  useEffect(() => {
    if (!requestCriteria) {
      setValue('contract', '');
    }
  }, [requestCriteria, setValue]);

  const { data, loading } = useRequestState<PaginateResult<Contract>>(
    () =>
      getContracts({
        page: 1,
        limit: 5,
        sortBy: 'name',
        order: 'asc',
        search,
        status: CONTRACT_STATUS.ACTIVE,
      }),
    [search],
    { condition: requestCriteria },
  );

  const handleChange = (value: Contract) => {
    setValue('contract', value);
  };

  return (
    <Box minH="300px">
      <InputGroup mb="20px">
        <InputLeftElement pointerEvents="none">
          <FaUser color="gray.300" />
        </InputLeftElement>

        <Input
          placeholder={t('invoice_form.contract_search')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <InputRightElement>
          <Spinner size="sm" visibility={loading ? 'visible' : 'hidden'} />
        </InputRightElement>
      </InputGroup>

      {errors.contract?.message && (
        <Text fontSize="sm" mt="10px" color="red.300">
          {errors?.contract?.message as string}
        </Text>
      )}

      <Controller
        name="contract"
        control={control}
        rules={{ required: t('form.required') }}
        defaultValue={null}
        render={() => (
          <RadioGroup value={value}>
            {data?.docs.filter.length ? (
              <Stack my="20px" w="100%">
                {data.docs
                  .reduce((acc, curr) => {
                    const currId = curr.data.client._id;
                    const hasClient = acc.find(({ data }) => data.client._id === currId);

                    if (!hasClient) acc.push(curr);

                    return acc;
                  }, [] as Contract[])
                  .map((data) => (
                    <Radio
                      key={data._id}
                      {...register('contract', { required: t('form.required') })}
                      onChange={() => handleChange(data)}
                    >
                      <Badge variant="outline" mx="5px" colorScheme="purple">
                        {t('invoice_form.contract_client')}
                        {': '}
                        {`${data.data.client.name} ${data.data.client.lastName}`}
                      </Badge>

                      <Badge variant="outline" mx="5px" colorScheme="green">
                        {t('invoice_form.contract_unit')}
                        {':'}
                        {data.data.unit.name}
                      </Badge>
                    </Radio>
                  ))}
              </Stack>
            ) : (
              <Box my="10px">
                <NoData />
              </Box>
            )}
          </RadioGroup>
        )}
      />
    </Box>
  );
};

export default ContractStep;
