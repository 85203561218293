import { request } from '@/utils/request';

export const getOrganizations = async (query: PaginationRequestType) =>
  request<PaginateResult<Organization<Subscription>>>({
    url: '/organization',
    query: { ...query },
  });

export const createOrganization = async (body: Partial<Organization>) =>
  request<'ok'>({
    url: '/organization',
    method: 'POST',
    body,
  });

export const updateOrganization = async (body: Partial<Organization>) =>
  request<'ok'>({
    url: '/organization',
    method: 'PUT',
    param: body._id,
    body,
  });
