import { Flex, Icon, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { MdSubscriptions } from 'react-icons/md';

import Subscriptions from '@/views/Settings/components/Subscriptions';
import AuthStore from '@/stores/auth';
import { isOrgAdmin } from '@/utils/admin';

const NoSubscriptionPopup = () => {
  const { profile } = AuthStore;
  const { t } = useTranslation();

  return isOrgAdmin(profile?.role) ? (
    <Flex direction="column" align="center" w="100%">
      <Text mb="20px" fontSize="2xl" textAlign="center" fontWeight="bold">
        {t('subscriptions.alerts.no_subscription.subscription_description')}
      </Text>

      <Subscriptions title={false} />
    </Flex>
  ) : (
    <Flex direction="column" align="center" gap="10px">
      <Flex align="center" justify="center" gap="10px">
        <Icon as={MdSubscriptions} fontSize="4xl" />

        <Text fontSize="xl" fontWeight="bold" textAlign="center">
          {t('subscriptions.alerts.no_subscription.message')}
        </Text>
      </Flex>

      <Text fontSize="xl" textAlign="center">
        {t('subscriptions.alerts.no_subscription.description')}
      </Text>
    </Flex>
  );
};

export default observer(NoSubscriptionPopup);
