import { useEffect, useState } from 'react';
import { HStack, Card, Flex, Icon, Button, Text, Switch, Badge } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IoGridOutline } from 'react-icons/io5';
import {
  FaHeartBroken,
  FaFolder,
  FaFileContract,
  FaUsers,
  FaChartPie,
  FaLink,
  FaCalendarAlt,
} from 'react-icons/fa';
import { MdHouse, MdSubscriptions, MdTimer } from 'react-icons/md';
import { observer } from 'mobx-react-lite';
import { TbActivity } from 'react-icons/tb';

import { HSeparator } from '@/components/Separator';
import useRequestState from '@/hooks/useRequestState';
import {
  getSubscriptionUrl,
  cancelSubscription,
  getOrgSubscription,
  getPlans,
  getPortalSession,
} from '@/api/subscriptions';
import { limitStats } from '@/api/stats';
import AppStore from '@/stores/app';
import { getTrialDaysLeft, timestampToFomattedDate } from '@/utils/date';
import {
  getSubscriptionStatusColor,
  getSubscriptionStatusText,
  isUnpaidStatus,
} from '@/utils/subscriptions';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { SUBSCRIPTION_INTERVALS } from '@/constants/app';
import { formatToTwoDecimals, getCurrencySymbol } from '@/utils/format';

type Props = {
  stats?: boolean;
  title?: boolean;
};

const Subscriptions = ({ stats = true, title = true }: Props) => {
  const [isSwitcherEnabled, setIsSwitcherEnabled] = useState(true);
  const [interval, setInterval] = useState<SubscriptionInterval>(
    SUBSCRIPTION_INTERVALS.YEAR,
  );

  const [idLoading, setIdLoading] = useState<string | null>(null);

  const { t } = useTranslation();
  const { setSubscription, subscription, organization } = AppStore;

  useEffect(() => {
    if (subscription?.interval) {
      setInterval(subscription.interval);
      setIsSwitcherEnabled(false);
    } else {
      setIsSwitcherEnabled(true);
    }
  }, [subscription]);

  const { data = null } = useRequestState<Subscription[]>(
    () => getPlans({ isActive: true }),
    [],
  );

  const status = subscription?.extra.status;
  const period = subscription?.extra.period;
  const cancel = subscription?.extra.cancel;
  const isUnpaid = isUnpaidStatus(status);

  const onPortalLinkSuccess = (data: string | null) => {
    if (data) {
      window.location.href = data;
    }
  };

  const { trigger: portalLinkTrigger, loading: portalLinkLoading } = useRequestState<
    string | null
  >(() => getPortalSession(), [], {
    condition: false,
    onSuccess: onPortalLinkSuccess,
  });

  const { data: limits, trigger: limitsTrigger } = useRequestState<LimitStats | null>(
    limitStats,
    [],
  );

  const hasActiveSub = typeof subscription === 'object' && !!subscription?._id;
  const hasActiveSubInList =
    hasActiveSub && !!data?.find((el) => el._id === subscription?._id);

  const onChange = () => {
    getOrgSubscription();
    getSubscriptionTrigger();
    limitsTrigger();
  };

  const handleUrlSuccess = (data: string | null | Subscription) => {
    if (typeof data === 'string') {
      // create subscription from scratch
      window.location.href = data;
    }

    onChange();
  };

  const handleCancelSuccess = (data: null | Subscription) => {
    onChange();
  };

  const { trigger: getUrlTrigger, loading: urlLoading } = useRequestState<string>(
    (id) => getSubscriptionUrl(id as string, window.location.href),
    [],
    {
      condition: false,
      onSuccess: handleUrlSuccess,
      onFinally: () => setIdLoading(null),
    },
  );

  const { trigger: getSubscriptionTrigger, loading: subscriptionLoading } =
    useRequestState<Subscription | null>(getOrgSubscription, [], {
      condition: false,
      onSuccess: setSubscription,
    });

  const { trigger: cancelTrigger, loading: cancelLoading } =
    useRequestState<Subscription | null>(cancelSubscription, [], {
      condition: false,
      onSuccess: handleCancelSuccess,
      onFinally: () => setIdLoading(null),
    });

  const handleClick = (id: string, isActive = false) => {
    setIdLoading(id);
    const shouldReactivate = isActive && !!cancel?.end;

    if (isActive && !shouldReactivate) {
      cancelTrigger();
    } else {
      getUrlTrigger(id);
    }
  };

  const handleIntervalChange = () => {
    const newInterval =
      interval === SUBSCRIPTION_INTERVALS.YEAR
        ? SUBSCRIPTION_INTERVALS.MONTH
        : SUBSCRIPTION_INTERVALS.YEAR;

    setInterval(newInterval as SubscriptionInterval);
  };

  const loading = urlLoading || cancelLoading || subscriptionLoading || portalLinkLoading;
  const trialLeft = getTrialDaysLeft(organization?.createdAt!, organization?.trialDays);
  const isUsageExceeded = getUsageExceeded(limits);

  return (
    <Flex direction="column" w="100%">
      {title && (
        <Flex direction="row" align="center" mb="20px" pl="15px" gap="15px">
          <Icon as={MdSubscriptions} fontSize="2xl" />
          <Text fontWeight="bold" fontSize="2xl">
            {t('settings.subscriptions.list')}
          </Text>
        </Flex>
      )}

      <Flex ml="15px" mb="7px" gap="10px">
        <Icon as={TbActivity} fontSize="xl" mt="1px" />

        <Text fontWeight="bold">{t('settings.subscriptions.status')}:</Text>

        <Text fontSize="md" fontWeight="bold" color={getSubscriptionStatusColor(status)}>
          {t(`settings.subscriptions.statuses.${getSubscriptionStatusText(status)}`)}
        </Text>
      </Flex>

      {isUnpaid && (
        <Flex ml="15px" mb="10px" gap="14px">
          <Icon as={FaLink} fontSize="md" mt="3px" />

          <Text fontWeight="bold">{t('settings.subscriptions.actions')}:</Text>

          <Flex gap="5px">
            <Button
              size="xs"
              borderRadius="5px"
              colorScheme="red"
              justifyContent="center"
              alignContent="center"
              disabled={loading}
              rightIcon={<ExternalLinkIcon fontSize="sm" />}
              onClick={portalLinkTrigger}
            >
              <Text fontWeight="bold">{t('settings.subscriptions.manage_payments')}</Text>
            </Button>
          </Flex>
        </Flex>
      )}

      {period?.start && period.end && (
        <Flex ml="15px" mb="10px" gap="12px">
          <Icon as={FaCalendarAlt} fontSize="lg" mt="2px" ml="0" />

          <Text fontWeight="bold">{t('settings.subscriptions.billing_period')}:</Text>

          <Badge colorScheme="green">{timestampToFomattedDate(period.start)}</Badge>
          {'-'}
          <Badge colorScheme="red">{timestampToFomattedDate(period.end)}</Badge>
        </Flex>
      )}

      {cancel?.end && (
        <Flex ml="15px" mb="10px" gap="12px">
          <Icon as={FaCalendarAlt} fontSize="lg" mt="2px" ml="0" />

          <Text fontWeight="bold">{t('settings.subscriptions.active_till')}:</Text>

          <Badge colorScheme="yellow">{timestampToFomattedDate(cancel?.end)}</Badge>
        </Flex>
      )}

      {trialLeft && (
        <Flex ml="15px" mb="10px" gap="12px">
          <Icon as={MdTimer} fontSize="xl" mt="1px" ml="-2px" />

          <Text fontWeight="bold">{t('settings.subscriptions.trial_days_left')}:</Text>

          <Text fontWeight="bold">{trialLeft}</Text>
        </Flex>
      )}

      {limits && stats && (
        <Flex w="100%" mb="20px" align="center" ml="16px">
          <Flex gap="13px">
            <Icon as={FaChartPie} fontSize="md" mt="3px" />
            <Text fontWeight="bold">{t('settings.subscriptions.usage')}:</Text>
          </Flex>

          <Flex direction="row" w="100%" mt="3px" wrap="wrap">
            <Flex
              gap="10px"
              ml="20px"
              color={isUsageExceeded.files ? 'red.300' : 'inherit'}
            >
              <Icon fontSize="lg" as={FaFolder} />
              <Text fontWeight="bold" fontSize="sm">
                {t('settings.subscriptions.files_limit')}: {limits.files.active} /{' '}
                {limits.files.limit ?? '0'}{' '}
                {t('settings.subscriptions.files_limit_measure')}
              </Text>
            </Flex>

            <Flex
              gap="10px"
              ml="20px"
              color={isUsageExceeded.contracts ? 'red.300' : 'inherit'}
            >
              <Icon fontSize="lg" as={FaFileContract} />
              <Text fontWeight="bold" fontSize="sm">
                {t('settings.subscriptions.contracts_limit')}: {limits.contracts.active} /{' '}
                {limits.contracts.limit ?? '0'}
              </Text>
            </Flex>

            <Flex
              gap="10px"
              ml="20px"
              color={isUsageExceeded.units ? 'red.300' : 'inherit'}
            >
              <Icon fontSize="lg" as={IoGridOutline} />
              <Text fontWeight="bold" fontSize="sm">
                {t('settings.subscriptions.units_limit')}: {limits.units.active} /{' '}
                {limits.units.limit ?? '0'}
              </Text>
            </Flex>

            <Flex
              gap="10px"
              ml="20px"
              color={isUsageExceeded.users ? 'red.300' : 'inherit'}
            >
              <Icon fontSize="xl" as={FaUsers} />
              <Text fontWeight="bold" fontSize="sm">
                {t('settings.subscriptions.users_limit')}: {limits.users.active} /{' '}
                {limits.users.limit ?? '0'}
              </Text>
            </Flex>

            <Flex
              gap="10px"
              ml="20px"
              color={isUsageExceeded.properties ? 'red.300' : 'inherit'}
            >
              <Icon fontSize="xl" as={MdHouse} />
              <Text fontWeight="bold" fontSize="sm">
                {t('settings.subscriptions.properties_limit')}: {limits.properties.active}{' '}
                / {limits.properties.limit ?? '0'}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}

      {isSwitcherEnabled && (
        <Flex ml="15px" mt="10px" mb="20px" gap="12px">
          <Switch
            variant="brand"
            isChecked={interval === SUBSCRIPTION_INTERVALS.YEAR}
            onChange={handleIntervalChange}
          />

          <Text fontWeight="bold">{t(`settings.subscriptions.circles.${interval}`)}</Text>
        </Flex>
      )}

      <HStack spacing="20px" overflowX="auto" w="100%" pb="40px">
        {!hasActiveSub && (
          <Card
            w="300px"
            minH="430px"
            flexShrink={0}
            alignSelf="stretch"
            p="20px"
            borderRadius="10px"
            border="1px solid"
            borderColor="brand.400"
            justify="center"
          >
            <Flex gap="10px" direction="column" align="center" opacity={0.5}>
              <Icon fontSize="3xl" as={FaHeartBroken} />

              <Text fontWeight="bold" fontSize="2xl" textAlign="center">
                {t('settings.subscriptions.no_subscription')}
              </Text>
            </Flex>
          </Card>
        )}

        {hasActiveSub && !hasActiveSubInList && (
          <SubscriptionElement
            el={subscription!}
            isActive={true}
            cancelled={!!cancel?.end}
            spinner={idLoading === subscription?._id}
            onClick={() => handleClick(subscription?._id!, true)}
          />
        )}

        {data
          ?.filter((el) => el.interval === interval)
          .map((el) => (
            <SubscriptionElement
              key={el._id}
              el={el}
              disabled={isUnpaid || isCurrentPriceHigher(subscription, el)}
              loading={loading}
              cancelled={!!cancel?.end}
              spinner={idLoading === el._id}
              isActive={subscription?._id === el._id}
              limits={limits}
              onClick={() => handleClick(el._id, subscription?._id === el._id)}
            />
          ))}
      </HStack>
    </Flex>
  );
};

const SubscriptionElement = ({
  el,
  isActive,
  limits,
  loading,
  disabled = false,
  spinner,
  cancelled = false,
  onClick,
}: {
  el: Subscription;
  isActive: boolean;
  limits?: LimitStats | null;
  loading?: boolean;
  disabled?: boolean;
  spinner?: boolean;
  cancelled?: boolean;
  onClick?: (id: string, isActive: boolean) => void;
}) => {
  const { t } = useTranslation();
  const limited = isElementOutOfLimit(el, limits);
  const outOfLimit = limited.atleastOne;
  const _disabled = disabled || outOfLimit || loading;

  return (
    <Card
      key={el._id}
      w="300px"
      flexShrink={0}
      p="20px"
      borderRadius="10px"
      border="1px solid"
      borderColor={isActive ? 'brand.400' : 'transparent'}
      transition="border-color 0.3s"
      _hover={{
        borderColor: 'brand.400',
      }}
    >
      <Text fontWeight="bold" fontSize="2xl" textAlign="center">
        {el.name}
      </Text>

      <HSeparator my="20px" />

      <DiscounterPrice el={el} />

      <HSeparator my="20px" />

      <Flex
        align="center"
        mb="10px"
        gap="10px"
        color={limited.files ? 'red.300' : 'inherit'}
      >
        <Icon fontSize="lg" as={FaFolder} />
        <Text>
          {t('settings.subscriptions.files_limit')}: {el.limits?.files}{' '}
          {t('settings.subscriptions.files_limit_measure')}
        </Text>
      </Flex>

      <Flex
        align="center"
        mb="10px"
        gap="10px"
        color={limited.contracts ? 'red.300' : 'inherit'}
      >
        <Icon fontSize="lg" as={FaFileContract} />
        <Text>
          {t('settings.subscriptions.contracts_limit')}: {el.limits?.contracts}
        </Text>
      </Flex>

      <Flex
        align="center"
        mb="10px"
        gap="10px"
        color={limited.units ? 'red.300' : 'inherit'}
      >
        <Icon fontSize="lg" as={IoGridOutline} />
        <Text>
          {t('settings.subscriptions.units_limit')}: {el.limits?.units}
        </Text>
      </Flex>

      <Flex
        align="center"
        mb="10px"
        gap="10px"
        color={limited.users ? 'red.300' : 'inherit'}
      >
        <Icon fontSize="xl" as={FaUsers} />
        <Text>
          {t('settings.subscriptions.users_limit')}: {el.limits?.users}
        </Text>
      </Flex>

      <Flex align="center" gap="10px" color={limited.properties ? 'red.300' : 'inherit'}>
        <Icon fontSize="xl" as={MdHouse} />
        <Text>
          {t('settings.subscriptions.properties_limit')}: {el.limits?.properties}
        </Text>
      </Flex>

      <HSeparator my="20px" />

      <Button
        w="100%"
        variant={outOfLimit ? 'outline' : !isActive ? 'brand' : 'outlineBrand'}
        disabled={_disabled}
        isLoading={spinner}
        onClick={() => onClick?.(el._id, isActive)}
      >
        {t(getButtonText(cancelled, isActive, outOfLimit))}
      </Button>
    </Card>
  );
};

const getButtonText = (cancelled: boolean, isActive: boolean, limitExceeded: boolean) => {
  if (limitExceeded) {
    return 'settings.subscriptions.limit_exceeded';
  }

  if (cancelled && isActive) {
    return 'settings.subscriptions.reactivate';
  }

  return isActive ? 'settings.subscriptions.cancel' : 'settings.subscriptions.activate';
};

const DiscounterPrice = ({ el }: { el: Subscription }) => {
  const { t } = useTranslation();
  const { price, currency, discount } = el;
  const text = t('settings.subscriptions.month_short');
  const currencySymbol = getCurrencySymbol(currency);

  if (discount <= 0) {
    return (
      <Text fontSize="2xl" textAlign="center" fontWeight="bold">
        {price} {currencySymbol} / {text}
      </Text>
    );
  }

  const mult = discount / 100;
  const discounted = formatToTwoDecimals(price * mult * 12);

  return (
    <>
      <Flex w="100%" justify="center" align="center">
        <Text as="s" fontSize="sm" mt="4px" mr="5px" color="gray.400">
          {price}
          {currencySymbol}
        </Text>

        <Text fontSize="2xl" fontWeight="bold">
          {price - price * mult}
          {currencySymbol} / {text}
        </Text>
      </Flex>

      <Flex justify="center">
        <Text fontSize="xs" color="green.200">
          {t('settings.subscriptions.discounted_price', {
            price: discounted,
            currency: currencySymbol,
          })}
        </Text>
      </Flex>
    </>
  );
};

const isElementOutOfLimit = (el: Subscription | null, limits?: LimitStats | null) => {
  if (!limits || !el) {
    return {
      files: false,
      contracts: false,
      units: false,
      users: false,
      properties: false,
      atleastOne: false,
    };
  }

  const files = el.limits.files < limits.files.active;
  const contracts = el.limits.contracts < limits.contracts.active;
  const units = el.limits.units < limits.units.active;
  const users = el.limits.users < limits.users.active;
  const properties = el.limits.properties < limits.properties.active;

  return {
    files,
    contracts,
    units,
    users,
    properties,
    atleastOne: files || contracts || units || users || properties,
  };
};

const getUsageExceeded = (limits?: LimitStats | null) => {
  if (!limits) {
    return {
      files: false,
      contracts: false,
      units: false,
      users: false,
      properties: false,
    };
  }

  return {
    files: limits.files.active > limits.files.limit!,
    contracts: limits.contracts.active > limits.contracts.limit!,
    units: limits.units.active > limits.units.limit!,
    users: limits.users.active > limits.users.limit!,
    properties: limits.properties.active > limits.properties.limit!,
  };
};

export const isCurrentPriceHigher = (curr: Subscription | null, next: Subscription) => {
  if (!curr) {
    return false;
  }

  return curr.price > next.price;
};

export default observer(Subscriptions);
