import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack,
  Table,
  Textarea,
  Thead,
  Tr,
  Td,
  Tbody,
  Th,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';

import MiniCalendar from '@/components/Calendar';
import { calculateItemsCost } from '@/utils/invoices';
import { CalendarIcon } from '@chakra-ui/icons';
import { INVOICE_TYPE } from '@/constants/app';
import { formateDateLocal } from '@/utils/date';

const PaymentStep = () => {
  const [dates, setDates] = useState<[Date, Date] | null>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const { t } = useTranslation();

  const {
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<InvoiceFormData>();

  const type = watch('type');
  const items = watch('items');
  const amount = watch('amount');
  const contract = watch('contract');
  const isDaily = type === INVOICE_TYPE.REGULAR;

  const reset = () => {
    // reset form values
    setDates(null);
    setValue('items', []);
    setValue('description', '');
    setValue('amount', 0);
  };

  const handleTypeChange = (value: InvoiceType) => {
    setValue('type', value);
    reset();
  };

  const handleDateChange = (dates: [Date, Date]) => {
    const { items, totalAmount } = calculateItemsCost(
      dates,
      contract!.data.unit.price,
      contract!.data.property.paymentCircle,
    );

    setDates(dates);
    setValue('items', items);
    setIsCalendarOpen(false);
    setValue('amount', totalAmount);
  };

  return (
    <Stack spacing={4}>
      <Flex gap={{ base: 4, md: 2 }} direction={{ base: 'column', md: 'row' }}>
        <FormControl>
          <FormLabel>{t('invoice_form.type')}</FormLabel>

          <Select
            value={type}
            {...register('type', { required: t('form.required') })}
            onChange={(e) => handleTypeChange(e.target.value as InvoiceType)}
          >
            <option value={INVOICE_TYPE.CUSTOM}>{t('invoice_form.type_simple')}</option>
            <option value={INVOICE_TYPE.REGULAR}>{t('invoice_form.type_daily')}</option>
          </Select>
        </FormControl>
      </Flex>

      {!isDaily && (
        <>
          <FormControl isRequired isInvalid={!!errors.amount}>
            <FormLabel>{t('invoice_form.payment_amount')}</FormLabel>

            <Input
              type="number"
              disabled={isDaily}
              {...register('amount', { required: t('form.required') })}
            />

            <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.description}>
            <FormLabel>{t('invoice_form.payment_product_description')}</FormLabel>

            <Textarea {...register('description', { required: t('form.required') })} />

            <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
          </FormControl>
        </>
      )}

      {isDaily && (
        <>
          <Button
            variant="brand"
            onClick={() => setIsCalendarOpen(true)}
            leftIcon={!dates ? <CalendarIcon /> : undefined}
          >
            {!dates && t('invoice_form.select_dates')}

            {dates && (
              <>
                {formateDateLocal(dates[0])} → {formateDateLocal(dates[1])}
              </>
            )}
          </Button>

          {items && items.length > 0 && (
            <>
              <Table variant="simple" size="sm" border="1px" borderColor="gray.500">
                <Thead>
                  <Tr>
                    <Th border="1px" borderColor="gray.500">
                      {t('invoice_form.payment_date')}
                    </Th>
                    <Th border="1px" borderColor="gray.500">
                      {t('invoice_form.day_price')}
                    </Th>
                    <Th border="1px" borderColor="gray.500">
                      {t('invoice_form.days_qty')}
                    </Th>
                    <Th border="1px" borderColor="gray.500">
                      {t('invoice_form.total_price')}
                    </Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {items.map((item, index) => (
                    <Tr key={index}>
                      <Td border="1px" borderColor="gray.500">
                        {item.translation?.params.date}
                      </Td>
                      <Td border="1px" borderColor="gray.500">
                        {item.dayPrice}
                      </Td>
                      <Td border="1px" borderColor="gray.500">
                        {item.quantity}
                      </Td>
                      <Td border="1px" borderColor="gray.500">
                        {item.price}
                      </Td>
                    </Tr>
                  ))}

                  <Tr fontWeight="bold">
                    <Td colSpan={3} border="1px" borderColor="gray.500">
                      {t('invoice_form.total_price')}
                    </Td>
                    <Td border="1px" borderColor="gray.500">
                      {amount}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </>
          )}
        </>
      )}

      <FormControl>
        <FormLabel>{t('invoice_form.comments')}</FormLabel>

        <Textarea {...register('comments')}></Textarea>
      </FormControl>

      <MiniCalendar
        selectRange
        isOpen={isCalendarOpen}
        value={dates}
        onChange={(data) => handleDateChange(data as [Date, Date])}
        onClose={() => setIsCalendarOpen(false)}
      />
    </Stack>
  );
};

export default PaymentStep;
