import { ORGANIZATION_DUE_PERIOD_DAYS } from '@/constants/app';
import { formatDate, timestampToDate } from './date';

export const getSubscriptionStatusColor = (status?: SubscriptionStatus | null) => {
  switch (status) {
    case 'active':
      return 'green.400';
    case 'past_due':
    case 'canceled':
    case 'unpaid':
      return 'red.400';
    case 'trialing':
      return 'blue.400';
    default:
      return 'gray';
  }
};

export const getSubscriptionStatusText = (status?: SubscriptionStatus | null) => {
  switch (status) {
    case 'active':
      return 'active';
    case 'past_due':
      return 'past_due';
    case 'canceled':
      return 'canceled';
    case 'unpaid':
      return 'unpaid';
    case 'trialing':
      return 'trialing';
    default:
      return 'unknown';
  }
};

export const isUnpaidStatus = (status?: SubscriptionStatus | null) => {
  switch (status) {
    case 'past_due':
    case 'canceled':
    case 'unpaid':
      return true;
    default:
      return false;
  }
};

export const isCriticalUnpaidStatus = (status?: SubscriptionStatus | null) => {
  switch (status) {
    case 'unpaid':
    case 'canceled':
      return true;
    default:
      return false;
  }
};

export const notCriticalUnpaidStatus = (status?: SubscriptionStatus | null) => {
  switch (status) {
    case 'past_due':
      return true;
    default:
      return false;
  }
};

export const hasSubscription = (status?: SubscriptionStatus | null) => {
  switch (status) {
    case 'active':
    case 'past_due':
    case 'trialing':
      return true;
    default:
      return false;
  }
};

export const daysLeftUntilCancelation = (startDate: number) => {
  const startDateMoment = timestampToDate(startDate);
  const endDate = startDateMoment.add(ORGANIZATION_DUE_PERIOD_DAYS, 'days');

  return formatDate(endDate.toDate());
};
