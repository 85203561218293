import { request } from '@/utils/request';

type GetPlansQuery = {
  isActive?: boolean;
  interval?: 'month' | 'year';
};

export const getPortalSession = async () =>
  request<string | null>({
    url: '/subscriptions/portal',
  });

export const getPlans = async (query?: GetPlansQuery) =>
  request<Subscription[]>({
    url: '/subscriptions',
    query,
  });

export const createPlan = async (body: Partial<Subscription>) =>
  request<'ok'>({
    url: '/subscriptions',
    method: 'POST',
    body,
  });

export const updatePlan = async (body: Partial<Subscription>) =>
  request<'ok'>({
    url: '/subscriptions',
    method: 'PUT',
    param: body._id,
    body,
  });

export const changeStatusPlan = async (id: string) =>
  request<'ok'>({
    url: '/subscriptions',
    method: 'PATCH',
    param: id,
  });

export const getSubscriptionUrl = async (id: Subscription['_id'], redirectUrl: string) =>
  request<string>({
    url: '/subscriptions/checkout',
    body: { id, redirectUrl },
    method: 'POST',
  });

export const cancelSubscription = async () =>
  request<Subscription | null>({
    url: '/subscriptions',
    method: 'DELETE',
  });

export const getOrgSubscription = async () =>
  request<Subscription | null>({
    url: '/subscriptions/organization',
  });
