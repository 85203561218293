import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Box,
  Button,
  HStack,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  useSteps,
} from '@chakra-ui/react';

import Popup from '@/components/Popup';
import useRequestState from '@/hooks/useRequestState';
import { createInvoice } from '@/api/invoice';
import ContractStep from '../steps/ContractStep';
import PaymentStep from '../steps/PaymentStep';
import { DEFAULT_FORM_DATA } from './config';

type Props = {
  data: null | undefined | Invoice;
  contract?: Contract;
  setSelected: (data: null | undefined) => void;
  onSubmit: (data: Invoice) => void;
};

const InvoiceForm = ({ data, contract, setSelected, onSubmit }: Props) => {
  const { t } = useTranslation();

  const steps = [
    { title: t('invoice_form.contract') },
    { title: t('invoice_form.payment_data') },
  ];

  const { activeStep, setActiveStep, goToNext, goToPrevious } = useSteps({
    index: contract ? 1 : 0,
    count: steps.length,
  });

  const _data = data ?? DEFAULT_FORM_DATA;

  const methods = useForm<InvoiceFormData>({
    mode: 'onChange',
    values: {
      ..._data,
      contract: contract ?? null,
    },
  });

  const { handleSubmit, getValues, reset } = methods;

  const handleOnClose = () => {
    reset();
    setActiveStep(contract ? 1 : 0);
    setSelected(undefined);
  };

  const handleSuccess = (data: Invoice) => {
    onSubmit(data);
    handleOnClose();
  };

  const { loading, trigger: submitTrigger } = useRequestState<Invoice>(
    () => {
      const body = { ...getValues() };

      return createInvoice(body);
    },
    [],
    { condition: false, onSuccess: handleSuccess },
  );

  const onNext = async () => {
    const isValid = await methods.trigger();

    if (isValid) {
      goToNext();
    }
  };

  const onBack = () => {
    goToPrevious();
  };

  const onSave = handleSubmit(submitTrigger);

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <ContractStep />;
      case 1:
        return <PaymentStep />;
      default:
        return null;
    }
  };

  return (
    <Popup
      size="xl"
      isOpen={data !== undefined}
      onClose={handleOnClose}
      closeOnOverlayClick={false}
      title={t(data ? 'invoice_form.update_invoice' : 'invoice_form.create_invoice')}
      content={
        <FormProvider {...methods}>
          <form onSubmit={onSave}>
            <Stepper index={activeStep} mt={4}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>

            <Box mt={8}>{renderStepContent()}</Box>
          </form>
        </FormProvider>
      }
      footer={
        <HStack>
          {activeStep === 0 && (
            <Button disabled={loading} variant="outline" onClick={handleOnClose}>
              {t('form.cancel')}
            </Button>
          )}

          {activeStep > 0 && (
            <Button disabled={loading || !!contract} variant="outline" onClick={onBack}>
              {t('form.back')}
            </Button>
          )}

          {activeStep < steps.length - 1 && (
            <Button disabled={loading} onClick={onNext}>
              {t('form.next')}
            </Button>
          )}

          {activeStep === steps.length - 1 && (
            <Button disabled={loading} type="submit" variant="brand" onClick={onSave}>
              {t('form.save')}
            </Button>
          )}
        </HStack>
      }
    />
  );
};

export default observer(InvoiceForm);
