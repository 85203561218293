import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import AuthFormlayout from './components/FormLayout';
import SignUpForm from './components/SignUp';
import OTP from './components/OtpForm';
import { ROUTES } from '@/constants/routes';
import usePageInfo from '@/hooks/usePageInfo';

const LoginPage = () => {
  usePageInfo({ title: 'pages.register' });

  const [step, setStep] = useState<LoginSteps>('email');

  const { t } = useTranslation();

  const methods = useForm<RegisterFormData>({
    mode: 'onChange',
  });

  return (
    <>
      <AuthFormlayout
        title={t('register.title')}
        leftContent={{
          text: t('register.already_have_account_text'),
          link: {
            href: ROUTES.AUTH,
            text: t('register.already_have_account_link'),
          },
        }}
      >
        <FormProvider {...methods}>
          {step === 'email' && <SignUpForm setStep={setStep} />}
          {step === 'code' && <OTP isRegister setStep={setStep} />}
        </FormProvider>
      </AuthFormlayout>
    </>
  );
};

export default LoginPage;
