import { useForm } from 'react-hook-form';
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Flex,
  Spinner,
  Button,
  HStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getStripeLink } from '@/api/stripe';
import useRequestState from '@/hooks/useRequestState';

type Props = {
  onClose: () => void;
  hasCancel?: boolean;
  extraBeforeContend?: React.ReactNode;
};

const StripeForm = ({ onClose, hasCancel = true, extraBeforeContend }: Props) => {
  const { t } = useTranslation();

  const {
    getValues,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<StripeUrlRequest>({
    defaultValues: { name: '' },
  });

  const handleOnClose = () => {
    onClose();
    reset();
  };

  const openNewTab = (data: StripeUrlResponse) => {
    if (!data) return;

    window.location.href = data.url;
  };

  const { trigger: getLinkTrigger, loading } = useRequestState<StripeUrlResponse>(
    () => getStripeLink(getValues(), window.location.href),
    [],
    {
      condition: false,
      onSuccess: (data) => {
        openNewTab(data);
        handleOnClose();
      },
    },
  );

  if (loading) {
    return (
      <Flex direction="column" align="center">
        <Spinner size="xl" mb="20px" />
      </Flex>
    );
  }

  return (
    <>
      {!loading && extraBeforeContend}

      <form>
        <Stack spacing={4} direction="row">
          <FormControl isRequired isInvalid={!!errors.name}>
            <FormLabel>{t('settings.stripe.name')}</FormLabel>

            <Input {...register('name', { required: t('form.required') })} />

            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
      </form>

      {!loading && (
        <HStack justifyContent="flex-end" my="20px">
          <Button
            disabled={loading}
            type="submit"
            variant="brand"
            onClick={handleSubmit(() => {
              getLinkTrigger();
            })}
          >
            {t('form.save')}
          </Button>

          {hasCancel && (
            <Button disabled={loading} variant="outline" onClick={() => handleOnClose()}>
              {t('form.cancel')}
            </Button>
          )}
        </HStack>
      )}
    </>
  );
};

export default StripeForm;
