import { CURRENCY, INVOICE_TYPE } from '@/constants/app';

export const DEFAULT_FORM_DATA: InvoiceFormData = {
  description: '',
  amount: 0,
  type: INVOICE_TYPE.REGULAR,
  currency: CURRENCY.USD,
  dueDate: new Date(),
  contract: null,
  items: undefined,
  comments: '',
};
