import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
} from '@chakra-ui/react';

import Popup from '@/components/Popup';
import useRequestState from '@/hooks/useRequestState';
import { createPlan, updatePlan } from '@/api/subscriptions';
import { DEFAULT_FORM_DATA } from './config';
import { CURRENCY, SUBSCRIPTION_INTERVALS } from '@/constants/app';

type Props = {
  data: Subscription | null | undefined;
  setSelected: (data: Subscription | null | undefined) => void;
  onSubmit: () => void;
};

const SubscriptionForm = ({ data, setSelected, onSubmit }: Props) => {
  const { t } = useTranslation();

  const methods = useForm<SubscriptionFormData>({
    mode: 'onChange',
    defaultValues: {
      ...DEFAULT_FORM_DATA,
    },
    values: data ?? DEFAULT_FORM_DATA,
  });

  const {
    handleSubmit,
    getValues,
    reset,
    register,
    formState: { errors },
  } = methods;

  const handleOnClose = () => {
    reset();
    setSelected(undefined);
  };

  const handleSuccess = () => {
    onSubmit();
    handleOnClose();
  };

  const { loading, trigger: submitTrigger } = useRequestState<'ok'>(
    () => {
      const body = { ...getValues() };

      return data ? updatePlan(body) : createPlan(body);
    },
    [],
    { condition: false, onSuccess: handleSuccess },
  );

  const onSave = handleSubmit(submitTrigger);

  return (
    <Popup
      size="2xl"
      isOpen={data !== undefined}
      onClose={handleOnClose}
      title={
        data?._id
          ? t('subscription_form.edit_subscription')
          : t('subscription_form.create_subscription')
      }
      content={
        <FormProvider {...methods}>
          <form onSubmit={onSave}>
            <Flex gap={{ base: 4, md: 2 }} direction="column">
              <FormControl isRequired isInvalid={!!errors.name}>
                <FormLabel>{t('subscription_form.name')}</FormLabel>

                <Input {...register('name', { required: t('form.required') })} />

                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              <Flex gap={{ base: 4, md: 2 }} direction={{ base: 'column', md: 'row' }}>
                <FormControl isRequired isInvalid={!!errors.price}>
                  <FormLabel>{t('subscription_form.price')}</FormLabel>

                  <Input
                    type="number"
                    {...register('price', { required: t('form.required') })}
                    disabled={!!data?._id}
                  />

                  <FormErrorMessage>{errors.price?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={!!errors.currency}>
                  <FormLabel>{t('subscription_form.currency')}</FormLabel>

                  <Select
                    {...register('currency', { required: t('form.required') })}
                    disabled={!!data?._id}
                  >
                    {Object.values(CURRENCY).map((currency) => (
                      <option key={currency} value={currency}>
                        {currency}
                      </option>
                    ))}
                  </Select>

                  <FormErrorMessage>{errors.currency?.message}</FormErrorMessage>
                </FormControl>
              </Flex>

              <Flex gap={{ base: 4, md: 2 }} direction={{ base: 'column', md: 'row' }}>
                <FormControl isRequired isInvalid={!!errors.discount}>
                  <FormLabel>{t('subscription_form.discount')}</FormLabel>

                  <Input
                    type="number"
                    {...register('discount', { required: t('form.required') })}
                    disabled={!!data?._id}
                  />

                  <FormErrorMessage>{errors.discount?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={!!errors.interval}>
                  <FormLabel>{t('subscription_form.interval')}</FormLabel>

                  <Select
                    {...register('interval', { required: t('form.required') })}
                    disabled={!!data?._id}
                  >
                    {Object.values(SUBSCRIPTION_INTERVALS).map((interval) => (
                      <option key={interval} value={interval}>
                        {interval}
                      </option>
                    ))}
                  </Select>

                  <FormErrorMessage>{errors.interval?.message}</FormErrorMessage>
                </FormControl>
              </Flex>

              <Flex gap={{ base: 4, md: 2 }} direction={{ base: 'column', md: 'row' }}>
                <FormControl isRequired isInvalid={!!errors.limits?.files}>
                  <FormLabel>{t('subscription_form.file_limit')}</FormLabel>

                  <Input
                    type="number"
                    {...register('limits.files', { required: t('form.required') })}
                  />

                  <FormErrorMessage>{errors.limits?.files?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={!!errors.limits?.contracts}>
                  <FormLabel>{t('subscription_form.contracts_limit')}</FormLabel>

                  <Input
                    type="number"
                    {...register('limits.contracts', { required: t('form.required') })}
                  />

                  <FormErrorMessage>{errors.limits?.contracts?.message}</FormErrorMessage>
                </FormControl>
              </Flex>

              <Flex gap={{ base: 4, md: 2 }} direction={{ base: 'column', md: 'row' }}>
                <FormControl isRequired isInvalid={!!errors.limits?.units}>
                  <FormLabel>{t('subscription_form.unit_limits')}</FormLabel>

                  <Input
                    type="number"
                    {...register('limits.units', { required: t('form.required') })}
                  />

                  <FormErrorMessage>{errors.limits?.units?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={!!errors.limits?.users}>
                  <FormLabel>{t('subscription_form.users_limit')}</FormLabel>

                  <Input
                    type="number"
                    {...register('limits.users', { required: t('form.required') })}
                  />

                  <FormErrorMessage>{errors.limits?.users?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={!!errors.limits?.properties}>
                  <FormLabel>{t('subscription_form.properties_limit')}</FormLabel>

                  <Input
                    type="number"
                    {...register('limits.properties', { required: t('form.required') })}
                  />

                  <FormErrorMessage>
                    {errors.limits?.properties?.message}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
            </Flex>
          </form>
        </FormProvider>
      }
      footer={
        <HStack>
          <Button disabled={loading} variant="outline" onClick={handleOnClose}>
            {t('form.cancel')}
          </Button>

          <Button disabled={loading} type="submit" variant="brand" onClick={onSave}>
            {t('form.save')}
          </Button>
        </HStack>
      }
    />
  );
};

export default observer(SubscriptionForm);
