import { Box, Flex, Text } from '@chakra-ui/react';

import LineChart from '@/components/Charts/LineChart';
import { lineChartOptionsTotalSpent } from '@/components/Charts/config';
import { useTranslation } from 'react-i18next';

type Props = {
  data: {
    name: string;
    data: number[];
  }[];
  title?: string;
  isYearly?: boolean;
};

export default function TotalSpent({ data, title, isYearly = false }: Props) {
  const { t } = useTranslation();

  const yearlyCategories = Array.from({ length: 12 }, (_, i) =>
    t(`month_by_number_short.${i}`),
  );

  const monthsCategories = Array.from(
    { length: data[0].data.length },
    (_, i) => `${i + 1}`,
  );

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column">
        <Text
          fontSize="xl"
          textAlign="start"
          fontWeight="700"
          lineHeight="100%"
          whiteSpace="nowrap"
        >
          {title}
        </Text>
      </Flex>

      <Box h="250px" w="100%" minW="100%">
        <LineChart
          chartData={data}
          chartOptions={{
            ...lineChartOptionsTotalSpent,
            xaxis: {
              ...lineChartOptionsTotalSpent.xaxis,
              categories: isYearly ? yearlyCategories : monthsCategories,
            },
          }}
        />
      </Box>
    </Flex>
  );
}
