import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import AppStore from '@/stores/app';
import { daysLeftUntilCancelation } from '@/utils/subscriptions';

const PastDuePopup = () => {
  const toast = useToast();
  const { t } = useTranslation();

  const { subscription } = AppStore;

  const startPeriod = subscription?.extra.period.start;
  const endDate = daysLeftUntilCancelation(startPeriod!);

  useEffect(() => {
    toast({
      id: 'past-due',
      title: t('subscriptions.alerts.past_due.unpaid_status'),
      description: t('subscriptions.alerts.past_due.account_blocked_on', {
        date: endDate,
      }),
      status: 'error',
      isClosable: true,
      duration: null,
    });
    // eslint-disable-next-line
  }, []);

  return null;
};

export default observer(PastDuePopup);
