import { DropzoneOptions, useDropzone } from 'react-dropzone';
import {
  Flex,
  FlexProps,
  Icon,
  Spinner,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdUpload } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

type Props = {
  disabled?: boolean;
  accept: DropzoneOptions['accept'];
  loading?: boolean;
  minH?: FlexProps['minH'];
  bg?: FlexProps['bg'];
  onDrop: DropzoneOptions['onDrop'];
};

const Upload = ({ onDrop, disabled, accept, loading, bg, ...rest }: Props) => {
  const brandColor = useColorModeValue('brand.500', 'white');
  const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
  const background = useColorModeValue('gray.100', 'navy.700');

  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple: false,
  });

  return (
    <Flex alignItems="center" minH="220px" w="100%" {...rest}>
      <Flex
        align="center"
        justify="center"
        bg={bg ?? background}
        border="1px dashed"
        borderColor={borderColor}
        borderRadius="16px"
        w="100%"
        h="max-content"
        minH="100%"
        _disabled={{ opacity: 0.5 }}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        {...getRootProps({ className: 'dropzone' })}
        p="20px"
      >
        {loading && (
          <Flex h="165px" justify="center" align="center">
            <Spinner size="xs" w="60px" h="60px" color={brandColor} />
          </Flex>
        )}

        {!loading && (
          <Flex direction="column" align="center" justify="center">
            <input {...getInputProps()} />

            <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />

            <Flex justify="center" mx="auto" mb="12px">
              <Text fontSize="xl" fontWeight="700" color={brandColor}>
                {t('components.upload_files')}
              </Text>
            </Flex>

            <Text
              fontSize="sm"
              fontWeight="500"
              color="secondaryGray.500"
              textAlign="center"
            >
              {t('components.file_types_allowed', {
                types: Object.values(accept!)
                  .map((type) => `${type}`.replace('.', '').toUpperCase())
                  .join(', '),
              })}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Upload;
