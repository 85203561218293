import { CURRENCY, SUBSCRIPTION_INTERVALS } from '@/constants/app';

export const DEFAULT_FORM_DATA: SubscriptionFormData = {
  name: '',
  price: 0,
  currency: CURRENCY.USD,

  discount: 0,
  interval: SUBSCRIPTION_INTERVALS.MONTH,

  limits: {
    files: 0,
    contracts: 0,
    units: 0,
    users: 0,
    properties: 0,
  },
};
