import { useCallback, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { Flex, Input, Button, Spinner, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { OTP_LENGTH } from '@/constants/app';
import { login, registerAccount } from '@/api/auth';
import useRequestState from '@/hooks/useRequestState';
import AuthStore from '@/stores/auth';
import { hideEmail } from '@/utils/format';

type Props = {
  isRegister?: boolean;
  setStep: (step: LoginSteps) => void;
};

const OTPForm = ({ isRegister = false, setStep }: Props) => {
  const [code, setCode] = useState('');

  const { t } = useTranslation();
  const { watch } = useFormContext<LoginFormData>();

  const email = watch('email');

  const handleCodeChange = (value: string) => {
    setCode(value);
  };

  const onLogin = useCallback(async (data: LoginResonse) => {
    AuthStore.setToken(data.token ?? null);
  }, []);

  const handleCancel = () => {
    setStep('email');
    setCode('');
  };

  const { trigger: loginTrigger, loading } = useRequestState<LoginResonse>(
    () => {
      if (isRegister) {
        return registerAccount(email, code);
      }

      return login(email, code);
    },
    [email, code],
    { condition: false, onSuccess: onLogin },
  );

  useEffect(() => {
    if (code.length === OTP_LENGTH) {
      loginTrigger();
    }
  }, [code, loginTrigger]);

  return (
    <Flex direction="column" w="100%">
      <Flex align="center" gap="5px">
        <Text whiteSpace="nowrap">{t('login.code_sent_to')}:</Text>

        <Text fontWeight="bold" ml="5px" color="primary" isTruncated>
          {hideEmail(email)}
        </Text>
      </Flex>

      <Flex
        w="100%"
        align="center"
        justify="center"
        my={{ base: '20px', md: '40px' }}
        flex={1}
      >
        <OtpInput
          value={code}
          onChange={handleCodeChange}
          numInputs={OTP_LENGTH}
          shouldAutoFocus
          skipDefaultStyles
          containerStyle={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          renderInput={(props) => (
            <Input
              w="50px"
              h="50px"
              mx="15px"
              variant="auth"
              textAlign="center"
              isDisabled={loading}
              {...props}
            />
          )}
        />
      </Flex>

      <Button
        fontSize="sm"
        variant="solid"
        fontWeight="500"
        w="100%"
        h="50"
        mb={{ base: 0, md: '20px' }}
        disabled={loading}
        onClick={handleCancel}
      >
        {loading ? <Spinner /> : t('login.back')}
      </Button>
    </Flex>
  );
};

export default observer(OTPForm);
