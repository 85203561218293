import moment from 'moment';

export const DEFAULT_FORM_DATA: ContractFormData = {
  unit: '',
  client: '',
  startDate: new Date(),
  endDate: moment().add(1, 'year').toDate(),
  comments: '',
  skipInitialInvoice: false,
  withDepositInitialCharge: true,
  withFullPeriodCharge: false,
};
