import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Button, Flex, HStack, useColorModeValue } from '@chakra-ui/react';
import { DropzoneOptions } from 'react-dropzone';

import Popup from '@/components/Popup';
import useRequestState from '@/hooks/useRequestState';
import Upload from '@/components/UploadZone';
import { uploadFile } from '@/api/files';
import { FILE_SIZE_LIMIT } from '@/constants/app';
import { showToast } from '@/utils/toast';

type Props = {
  isOpen: boolean;
  accept: DropzoneOptions['accept'];
  folder?: string;
  group?: IFileCollections;
  setOpen: (value: boolean) => void;
  dataTrigger?: () => void;
};

const UploadFileModal = ({
  setOpen,
  dataTrigger,
  isOpen,
  accept,
  group,
  folder,
}: Props) => {
  const bg = useColorModeValue('white', 'gray.800');

  const { t } = useTranslation();

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleSuccess = () => {
    dataTrigger?.();
    handleOnClose();
  };

  const handleOnDrop = async (files: File[]) => {
    if (files.length) {
      const formData = new FormData();

      if (files[0].size > FILE_SIZE_LIMIT) {
        showToast(t('files.file_size_limit', { size: FILE_SIZE_LIMIT / 1024 / 1024 }));

        return;
      }

      formData.append('file', files[0]);

      if (group) formData.append('group', group);
      if (folder) formData.append('folder', folder);

      submitTrigger(formData);
    }
  };

  const { loading, trigger: submitTrigger } = useRequestState<'ok'>(
    (data) => uploadFile(data as FormData),
    [],
    {
      condition: false,
      onSuccess: handleSuccess,
    },
  );

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleOnClose}
      closeOnOverlayClick={false}
      title={t('files_form.upload_file')}
      content={
        <Flex direction="column" justify="center" align="center">
          <Upload
            loading={loading}
            minH="300px"
            bg={bg}
            onDrop={handleOnDrop}
            accept={accept}
          />
        </Flex>
      }
      footer={
        <HStack>
          <Button disabled={loading} variant="outline" onClick={handleOnClose}>
            {t('form.cancel')}
          </Button>
        </HStack>
      }
    />
  );
};

export default observer(UploadFileModal);
