import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FcGoogle } from 'react-icons/fc';
import { useGoogleLogin } from '@react-oauth/google';

import { googleLogin } from '@/api/auth';
import useRequestState from '@/hooks/useRequestState';
import AuthStore from '@/stores/auth';

const GoogleLoginButton = () => {
  const { t } = useTranslation();

  const onLogin = useCallback(async (data: LoginResonse) => {
    AuthStore.setToken(data.token ?? null);
  }, []);

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (token) => googleLoginTrigger({ token: token.access_token }),
    onError: () => {},
    flow: 'implicit',
  });

  const { trigger: googleLoginTrigger } = useRequestState<
    LoginResonse,
    { token: string }
  >((data) => googleLogin(data!), [], {
    condition: false,
    onSuccess: onLogin,
  });

  return (
    <Button
      fontSize="sm"
      me="0px"
      mb="26px"
      py="15px"
      h="50px"
      fontWeight="500"
      onClick={() => handleGoogleLogin()}
    >
      <Icon as={FcGoogle} w="20px" h="20px" me="10px" />

      {t('login.sing_in_google')}
    </Button>
  );
};

export default observer(GoogleLoginButton);
