import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { HiCursorClick } from 'react-icons/hi';
import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import AppStore from '@/stores/app';
import AuthStore from '@/stores/auth';
import { isAdmin, isOrgManager } from '@/utils/admin';
import { ROUTES } from '@/constants/routes';

const SelectProperty = () => {
  const { t } = useTranslation();
  const { properties } = AppStore;
  const { profile } = AuthStore;
  const navigate = useNavigate();

  return (
    <Flex
      alignSelf="center"
      justify="center"
      minH={'100%'}
      pb="20px"
      direction="column"
      maxW="300px"
    >
      <Flex direction="row" justify="center" align="center" mb="30px" gap="10px">
        <Icon as={HiCursorClick} fontSize="2xl" />

        <Text fontSize="2xl">{t('general.property_not_selected_title')}</Text>
      </Flex>

      {isOrgManager(profile?.role) && (
        <Text align="center" mb="20px">
          {t(
            properties.length
              ? 'general.property_not_selected_description'
              : 'general.property_no_length',
          )}
        </Text>
      )}

      {isAdmin(profile?.role) && (
        <>
          <Text align="center" mb="20px">
            {t(
              properties.length
                ? 'general.property_not_selected_description'
                : 'general.property_no_length_admin',
            )}
          </Text>

          {!properties.length && (
            <Button variant="brand" onClick={() => navigate(ROUTES.OBJECTS)}>
              {t('general.property_no_length_admin_link')}
            </Button>
          )}
        </>
      )}
    </Flex>
  );
};

export default observer(SelectProperty);
