import { createStandaloneToast } from '@chakra-ui/react';

import { DEFAULT_TOAST_OPTIONS } from '@/constants/ui';

type Status = 'success' | 'error' | 'warning' | 'info';

const toast = createStandaloneToast();

export const showToast = (
  description: string,
  status: Status = 'error',
  title?: string,
) => {
  const toastId = `toast-${title || description}`;

  toast.toast({
    ...DEFAULT_TOAST_OPTIONS,
    id: toastId,
    title,
    status,
    description,
  });
};
