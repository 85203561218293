import { Flex } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import Popup from '@/components/Popup';
import AppStore from '@/stores/app';
import { isCriticalUnpaidStatus, notCriticalUnpaidStatus } from '@/utils/subscriptions';
import NoSubscription from './components/NoSubscription';
import CriticalUnpaid from './components/CriticalUnpaid';
import PastDue from './components/PastDue';

const Onboarding = () => {
  const { subscription } = AppStore;

  const status = subscription?.extra.status;
  const isOpen = !subscription || isCriticalUnpaidStatus(status);

  return (
    <>
      <Popup
        isCentered
        withCloseButton={false}
        size={'4xl'}
        isOpen={isOpen}
        onClose={() => {}}
        content={
          <Flex flex={1} w="100%" justify="center" align="center" p="20px">
            <Content />
          </Flex>
        }
      />

      {notCriticalUnpaidStatus(status) && <PastDue />}
    </>
  );
};

const Content = observer(() => {
  const { subscription } = AppStore;

  const status = subscription?.extra.status;

  if (!subscription) {
    return <NoSubscription />;
  }

  if (isCriticalUnpaidStatus(status)) {
    return <CriticalUnpaid />;
  }

  return null;
});

export default observer(Onboarding);
