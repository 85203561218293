export enum ROUTES {
  // AUTH
  AUTH = '/auth',
  AUTH_REGISTER = '/auth/register',

  // ROOT
  ROOT = '/admin',

  // MANAGER DASHBOARD
  DASHBOARD = '/admin/dashboard',
  CONTRACTS = '/admin/contracts',
  UNITS = '/admin/units',
  INVOICES = '/admin/invoices',
  CLIENTS = '/admin/clients',
  PAYMENTS = '/admin/invoices',
  ANNOUNCEMENTS = '/admin/announcements',
  TASKS = '/admin/tasks',
  FILES = '/admin/files',

  // ADMIN DASHBOARD
  USERS = '/admin/users',
  OBJECTS = '/admin/properties',
  COMPLAINS = '/admin/communication',
  SETTINGS = '/admin/settings',

  // SUPER ADMIN DASHBOARD
  ORGANIZATIONS = '/admin/organizations',
  IMPORT_EXPORT = '/admin/import-export',
  SUBSCRIPTIONS = '/admin/subscriptions',
}

export enum STATIC_PAGES {
  PRIVACY_POLICY = '/privacy.html',
  TERMS_OF_SERVICE = '/terms.html',
}
