export const stepStyles = {
  components: {
    Stepper: {
      baseStyle: {
        indicator: {
          '&[data-status=complete]': {
            bgColor: 'brand.400',
            borderColor: 'brand.400',
            color: 'white',
          },
          '&[data-status=active]': {
            bgColor: 'brand.400',
            borderColor: 'brand.400',
            color: 'white',
          },
        },
      },
    },
  },
};
