import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, HStack } from '@chakra-ui/react';

import Popup from '@/components/Popup';
import useRequestState from '@/hooks/useRequestState';
import { DEFAULT_FORM_DATA } from './config';
import { createOrganization, updateOrganization } from '@/api/organization';
import Contact from '../steps/ContactInfo';

type Props = {
  data: Organization | Organization<Subscription> | null | undefined;
  setSelected: (
    data: Organization | Organization<Subscription> | null | undefined,
  ) => void;
  onSubmit: () => void;
};

const OrganizationForm = ({ data, setSelected, onSubmit }: Props) => {
  const { t } = useTranslation();

  const methods = useForm<OrganizationFormData>({
    mode: 'onChange',
    defaultValues: {
      ...DEFAULT_FORM_DATA,
    },
    values: data ?? DEFAULT_FORM_DATA,
  });

  const { handleSubmit, getValues, reset } = methods;

  const handleOnClose = () => {
    reset();
    setSelected(undefined);
  };

  const handleSuccess = () => {
    onSubmit();
    handleOnClose();
  };

  const { loading, trigger: submitTrigger } = useRequestState<'ok'>(
    () => {
      const body = { ...getValues() };

      return data ? updateOrganization(body) : createOrganization(body);
    },
    [],
    { condition: false, onSuccess: handleSuccess },
  );

  const onSave = handleSubmit(submitTrigger);

  return (
    <Popup
      size="2xl"
      isOpen={data !== undefined}
      onClose={handleOnClose}
      title={
        data?._id
          ? t('organization_form.edit_organization')
          : t('organization_form.create_organization')
      }
      content={
        <FormProvider {...methods}>
          <form onSubmit={onSave}>
            <Contact />
          </form>
        </FormProvider>
      }
      footer={
        <HStack>
          <Button disabled={loading} variant="outline" onClick={handleOnClose}>
            {t('form.cancel')}
          </Button>

          <Button disabled={loading} type="submit" variant="brand" onClick={onSave}>
            {t('form.save')}
          </Button>
        </HStack>
      }
    />
  );
};

export default observer(OrganizationForm);
