import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { GrAlert } from 'react-icons/gr';

import useRequestState from '@/hooks/useRequestState';
import { getPortalSession } from '@/api/subscriptions';

const PastDuePopup = () => {
  const { t } = useTranslation();

  const onPortalLinkSuccess = (data: string | null) => {
    if (data) {
      window.location.href = data;
    }
  };

  const { trigger, loading } = useRequestState<string | null>(
    () => getPortalSession(),
    [],
    {
      condition: false,
      onSuccess: onPortalLinkSuccess,
    },
  );

  return (
    <Flex direction="column" align="center" gap="20px">
      <Flex align="center" justify="center" gap="10px">
        <Icon as={GrAlert} fontSize="3xl" color="red.400" />

        <Text fontSize="xl" fontWeight="bold" textAlign="center">
          {t('subscriptions.alerts.unpaid.title')}
        </Text>
      </Flex>

      <Text fontSize="lg" textAlign="center">
        {t('subscriptions.alerts.unpaid.account_blocked')}
      </Text>

      <Button colorScheme="red" bg="red.400" isLoading={loading} onClick={trigger}>
        {t('subscriptions.alerts.unpaid.update_payment_method')}
      </Button>
    </Flex>
  );
};

export default observer(PastDuePopup);
