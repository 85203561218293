import { ADMIN_ROLE } from '@/constants/app';

export const isOrgManager = (role?: AdminRole) => {
  return role === ADMIN_ROLE.MANAGER;
};

export const isOrgAdmin = (role?: AdminRole) => {
  return role === ADMIN_ROLE.ADMIN;
};

export const isAdmin = (role?: AdminRole) => {
  return role === ADMIN_ROLE.ADMIN || role === ADMIN_ROLE.SUPER_ADMIN;
};

export const isSuperAdmin = (role?: AdminRole) => {
  return role === ADMIN_ROLE.SUPER_ADMIN;
};
