import React from 'react';
import {
  HStack,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Flex,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon } from '@chakra-ui/icons';

import { PAGINATION_SIZE_OPTIONS } from '@/constants/app';

interface PaginationProps extends Partial<PaginateResult> {
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: PaginationLimits) => void;
  limit: number;
}

const Pagination: React.FC<PaginationProps> = ({
  hasPrevPage = false,
  hasNextPage = false,
  page = 1,
  totalPages = 1,
  prevPage = null,
  nextPage = null,
  limit,
  onPageChange,
  onPageSizeChange,
}) => {
  const maxPageButtons = 2;
  const pages = getPaginationButtons(totalPages, page, maxPageButtons, onPageChange);

  if (totalPages <= 1) {
    return <Flex mt="20px" />;
  }

  return (
    <HStack justifyContent="center" alignItems="center" spacing={2} height="80px">
      <IconButton
        aria-label="Previous page"
        onClick={() => onPageChange(prevPage || 1)}
        isDisabled={!hasPrevPage}
        icon={<ChevronLeftIcon fontSize="lg" />}
      />

      {pages}

      <IconButton
        aria-label="Next page"
        onClick={() => onPageChange(nextPage || totalPages)}
        isDisabled={!hasNextPage}
        icon={<ChevronRightIcon fontSize="lg" />}
      />

      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="solid" ml="auto">
          {limit}
        </MenuButton>

        <MenuList>
          {PAGINATION_SIZE_OPTIONS.map((size) => (
            <MenuItem key={size} onClick={() => onPageSizeChange(size)}>
              <Text fontSize="sm">{size}</Text>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </HStack>
  );
};

const getPaginationButtons = (
  totalPages: number,
  currentPage: number,
  maxPageButtons: number,
  onPageChange: (page: number) => void,
) => {
  const pages = [];

  if (totalPages <= maxPageButtons + 2) {
    // Show all pages
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <Button
          key={i}
          onClick={() => onPageChange(i)}
          variant="ghost"
          isDisabled={currentPage === i}
          bg={currentPage === i ? 'blue.500' : 'transparent'}
          color={currentPage === i ? 'white' : 'inherit'}
          _hover={{ bg: 'gray.500' }}
          _disabled={{
            bg: 'blue.500',
            color: 'white',
            cursor: 'default',
            _hover: { bg: 'blue.500' },
          }}
          minWidth="40px"
        >
          {i}
        </Button>,
      );
    }
  } else {
    const halfRange = Math.floor(maxPageButtons / 2);
    let left = currentPage - halfRange;
    let right = currentPage + halfRange;

    if (left < 2) {
      left = 2;
      right = left + maxPageButtons - 1;
    }

    if (right > totalPages - 1) {
      right = totalPages - 1;
      left = right - maxPageButtons + 1;
    }

    // First page
    pages.push(
      <Button
        key={1}
        onClick={() => onPageChange(1)}
        variant="ghost"
        isDisabled={currentPage === 1}
        bg={currentPage === 1 ? 'blue.500' : 'transparent'}
        color={currentPage === 1 ? 'white' : 'inherit'}
        _hover={{ bg: 'gray.500' }}
        _disabled={{
          bg: 'blue.500',
          color: 'white',
          cursor: 'default',
          _hover: { bg: 'blue.500' },
        }}
        minWidth="40px"
      >
        {1}
      </Button>,
    );

    if (left > 2) {
      pages.push(
        <Text key="ellipsis1" px={2}>
          ...
        </Text>,
      );
    }

    for (let i = left; i <= right; i++) {
      pages.push(
        <Button
          key={i}
          onClick={() => onPageChange(i)}
          variant="ghost"
          isDisabled={currentPage === i}
          bg={currentPage === i ? 'blue.500' : 'transparent'}
          color={currentPage === i ? 'white' : 'inherit'}
          _hover={{ bg: 'gray.500' }}
          _disabled={{
            bg: 'blue.500',
            color: 'white',
            cursor: 'default',
            _hover: { bg: 'blue.500' },
          }}
          minWidth="40px"
        >
          {i}
        </Button>,
      );
    }

    if (right < totalPages - 1) {
      pages.push(
        <Text key="ellipsis2" px={2}>
          ...
        </Text>,
      );
    }

    // Last page
    pages.push(
      <Button
        key={totalPages}
        onClick={() => onPageChange(totalPages)}
        variant="ghost"
        isDisabled={currentPage === totalPages}
        bg={currentPage === totalPages ? 'blue.500' : 'transparent'}
        color={currentPage === totalPages ? 'white' : 'inherit'}
        _hover={{ bg: 'gray.500' }}
        _disabled={{
          bg: 'blue.500',
          color: 'white',
          cursor: 'default',
          _hover: { bg: 'blue.500' },
        }}
        minWidth="40px"
      >
        {totalPages}
      </Button>,
    );
  }

  return pages;
};

export default Pagination;
