import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

const OrganizationContact = () => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    register,
  } = useFormContext<OrganizationFormData>();

  return (
    <Stack spacing={4}>
      <Flex gap={{ base: 4, md: 2 }} direction="column">
        <Flex
          gap={{
            base: 4,
            md: 2,
          }}
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          {/* Name */}
          <FormControl isRequired isInvalid={!!errors.name}>
            <FormLabel>{t('organization_form.name')}</FormLabel>

            <Input {...register('name', { required: t('form.required') })} />

            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.email} isRequired>
            <FormLabel>{t('organization_form.email')}</FormLabel>

            <Input {...register('email', { required: t('form.required') })} />

            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default OrganizationContact;
