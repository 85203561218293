import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { InfoIcon } from '@chakra-ui/icons';

import AppStore from '@/stores/app';
import { PAYMENT_CIRCLE } from '@/constants/app';

const CommentsStep = () => {
  const { t } = useTranslation();

  const { property } = AppStore;
  const isDaily = property.paymentCircle === PAYMENT_CIRCLE.DAILY;

  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext<ContractFormData>();

  const skipInitialInvoice = watch('skipInitialInvoice');

  return (
    <Stack spacing={4}>
      <Flex gap={{ base: 4, md: 2 }} direction="column">
        <FormControl isInvalid={!!errors.comments}>
          <FormLabel>{t('contract_form.comments')}</FormLabel>

          <Textarea
            placeholder={t('contract_form.comments')}
            rows={6}
            resize="none"
            maxLength={200}
            {...register('comments')}
          />

          <FormErrorMessage>{errors.comments?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <Flex align="center">
            <Checkbox {...register('skipInitialInvoice')}>
              {t('contract_form.skip_initial_invoice')}
            </Checkbox>

            <Tooltip
              label={t('contract_form.skip_initial_invoice_info')}
              aria-label="skip initial invoice info"
            >
              <InfoIcon ml={2} />
            </Tooltip>
          </Flex>
        </FormControl>

        <FormControl>
          <Flex align="center">
            <Checkbox
              {...register('withDepositInitialCharge')}
              disabled={skipInitialInvoice}
            >
              {t('contract_form.withDepositInitialCharge')}
            </Checkbox>
          </Flex>
        </FormControl>

        {isDaily && (
          <FormControl>
            <Flex align="center">
              <Checkbox
                {...register('withFullPeriodCharge')}
                disabled={skipInitialInvoice}
              >
                {t('contract_form.with_full_period_charge')}
              </Checkbox>
            </Flex>
          </FormControl>
        )}
      </Flex>
    </Stack>
  );
};

export default observer(CommentsStep);
