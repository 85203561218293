import { request } from '@/utils/request';
import { stringToBool } from '@/utils/format';

type GetPropertyQuery = {
  isActive: boolean;
};

export const getPropertiesProfile = async (query: GetPropertyQuery) =>
  request<Property[]>({
    url: '/property/profile',
    query,
  });

export const getProperties = async () =>
  request<Property[]>({
    url: '/property',
  });

export const createProperty = async (body: PropertyFormData) =>
  request<Property>({
    url: '/property',
    method: 'POST',
    body: {
      ...body,
      isManualInvoicing: stringToBool(body.isManualInvoicing),
    },
  });

export const updateProperty = async (body: PropertyFormData) =>
  request<Property>({
    url: '/property',
    method: 'PUT',
    body: {
      ...body,
      isManualInvoicing: stringToBool(body.isManualInvoicing),
    },
    param: body._id,
  });

export const deleteProperty = async (id: string) =>
  request<'ok'>({
    url: '/property',
    method: 'DELETE',
    param: id,
  });
