import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MdSubscriptions } from 'react-icons/md';

import AuthStore from '@/stores/auth';
import { isAdmin, isOrgManager } from '@/utils/admin';
import { ROUTES } from '@/constants/routes';

const NoSubscription = () => {
  const { t } = useTranslation();
  const { profile } = AuthStore;
  const navigate = useNavigate();

  return (
    <Flex
      alignSelf="center"
      justify="center"
      minH={'100%'}
      pb="20px"
      direction="column"
      maxW="300px"
    >
      <Flex direction="row" justify="center" align="center" mb="30px" gap="10px">
        <Icon as={MdSubscriptions} fontSize="2xl" />

        <Text fontSize="2xl">{t('general.no_active_subscription')}</Text>
      </Flex>

      {isOrgManager(profile?.role) && (
        <Text align="center" mb="20px">
          {t('general.no_active_subscription_manager')}
        </Text>
      )}

      {isAdmin(profile?.role) && (
        <>
          <Text align="center" mb="20px">
            {t('general.no_active_subscription_admin')}
          </Text>

          <Button variant="brand" onClick={() => navigate(ROUTES.SETTINGS)}>
            {t('general.no_active_subscription_admin_link')}
          </Button>
        </>
      )}
    </Flex>
  );
};

export default observer(NoSubscription);
