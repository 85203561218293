import { useCallback, useMemo } from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getProfile } from '@/api/admin';
import { getPropertiesProfile } from '@/api/property';
import AuthStore from '@/stores/auth';
import LayoutStore from '@/stores/layout';
import AppStore from '@/stores/app';
import useRequestState from '@/hooks/useRequestState';
import { isOrgAdmin, isOrgManager, isSuperAdmin } from '@/utils/admin';
import Sidebar, { SidebarResponsive } from './components/Sidebar';
import AdminNavbar from './components/NavBar';
import SelectProperty from './components/SelectProperty';
import Subscription from './components/Subscription';
import { getTrialDaysLeft } from '@/utils/date';
import { showToast } from '@/utils/toast';
import { AvoidRouteConditionRender } from '@/components/PageCondition';
import { ROUTES } from '@/constants/routes';
import { getOrgSubscription } from '@/api/subscriptions';
import { hasSubscription } from '@/utils/subscriptions';
import NoSubscription from './components/NoSubscription';

const AdminLayout = () => {
  const { pageTitle } = LayoutStore;
  const { setProfile, profile } = AuthStore;
  const {
    selectedProperty,
    setOrganization,
    setProperties,
    setSubscription,
    organization,
  } = AppStore;

  const matches = useMatches();

  const { t } = useTranslation();

  const hasSub = hasSubscription(organization?.subscriptionStatus);

  const propertySpecific = useMemo(
    () =>
      matches.some((data) => {
        const route = data as CustomRouteConfig;

        return !!route.handle?.propertySpecific;
      }),
    [matches],
  );

  const getTrialNotification = (org: Organization) => {
    const days = getTrialDaysLeft(org.createdAt, org.trialDays);

    if (days) {
      showToast(
        `${t('settings.subscriptions.trial_days_left')}: ${days}`,
        getToastColor(days),
      );
    }
  };

  const onProfileSuccess = useCallback(
    (profile: Profile) => {
      if (profile) {
        setProfile(profile);
        setOrganization(profile.organization);
        getTrialNotification(profile.organization);
      }
    },
    // eslint-disable-next-line
    [setProfile, setOrganization],
  );

  const onPropertySuccess = useCallback(
    (data: Property[]) => {
      setProperties(data);
    },
    [setProperties],
  );

  const { loading: subscriptionLoading } = useRequestState<Subscription | null>(
    getOrgSubscription,
    [],
    { onSuccess: setSubscription },
  );

  const { loading: profileLoading } = useRequestState<Profile>(() => getProfile(), [], {
    onSuccess: onProfileSuccess,
  });

  const { loading: propertyLoading } = useRequestState<Property[]>(
    () => getPropertiesProfile({ isActive: true }),
    [organization?._id],
    { onSuccess: onPropertySuccess, condition: !!organization?._id },
  );

  const loading = profileLoading || propertyLoading || subscriptionLoading;

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        width="100%"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <>
      <Sidebar />

      <SidebarResponsive />

      <Flex
        overflow="auto"
        position="relative"
        direction="column"
        ml={{ base: 0, xl: '300px' }}
        px="20px"
        pt="100px"
        h="100vh"
        w={{ base: '100%', xl: 'calc( 100% - 300px )' }}
        maxWidth={{ base: '100%', xl: 'calc( 100vw - 300px )' }}
        justifyContent="stretch"
      >
        <Flex w="100%" flex={1} direction="column">
          <Content
            role={profile?.role}
            hasSub={hasSub}
            hasSelectedProperty={!!selectedProperty}
            propertySpecific={propertySpecific}
          />
        </Flex>
      </Flex>

      <AdminNavbar title={pageTitle} />

      <AvoidRouteConditionRender avoidRoutes={[ROUTES.SETTINGS]}>
        {(isOrgAdmin(profile?.role) || isOrgManager(profile?.role)) && <Subscription />}
      </AvoidRouteConditionRender>
    </>
  );
};

type ContentProps = {
  role?: Profile['role'];
  hasSub: boolean;
  hasSelectedProperty: boolean;
  propertySpecific: boolean;
};

const Content = ({
  hasSub,
  hasSelectedProperty,
  propertySpecific,
  role,
}: ContentProps) => {
  if (!hasSub && !isSuperAdmin(role)) {
    return (
      <AvoidRouteConditionRender
        avoidRoutes={[
          ROUTES.SETTINGS,
          ROUTES.ORGANIZATIONS,
          ROUTES.SUBSCRIPTIONS,
          ROUTES.IMPORT_EXPORT,
        ]}
        avoidComponent={<Outlet />}
      >
        <NoSubscription />
      </AvoidRouteConditionRender>
    );
  }

  if (!hasSelectedProperty && propertySpecific) {
    return <SelectProperty />;
  }

  return <Outlet />;
};

const getToastColor = (daysLeft: number): 'success' | 'error' | 'warning' | 'info' => {
  if (daysLeft < 3) return 'error';
  if (daysLeft <= 7) return 'success';

  return 'success';
};

export default observer(AdminLayout);
