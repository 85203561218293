import { observable, action, makeObservable, computed } from 'mobx';

import { getItem, removeItem, setItem } from '@/utils/storage';
import logger from '@/utils/logger';

class AppClass {
  constructor() {
    makeObservable(this, {
      selectedProperty: observable,
      organization: observable,
      properties: observable,
      subscription: observable,
      property: computed,
      setOrganization: action,
      setProperties: action,
      setSelectedProperty: action,
      setSubscription: action,
    });
  }

  selectedProperty = getItem('SELECTED_PROPERTY') || null;
  organization: Organization | null = null;
  properties: Record<Property['_id'], Property> = {};

  subscription: Subscription | null = null;

  get property() {
    return this.properties[this.selectedProperty!] ?? null;
  }

  setSelectedProperty = (property: Property['_id'] | null) => {
    if (property) {
      setItem('SELECTED_PROPERTY', property);

      this.selectedProperty = property;
    } else {
      this.selectedProperty = null;
      removeItem('SELECTED_PROPERTY');
    }
  };

  setProperties = (properties: Property[]) => {
    if (!properties.length) {
      this.properties = {};
      this.setSelectedProperty(null);

      return;
    }

    const firstProperty = properties[0]._id;

    this.properties = properties.reduce((acc: any, property: any) => {
      acc[property._id] = property;

      return acc;
    }, {});

    if (!this.selectedProperty) {
      this.setSelectedProperty(firstProperty);
    }

    if (!this.properties[this.selectedProperty!]) {
      this.setSelectedProperty(firstProperty);
    }
  };

  setOrganization = (organization: Organization | null) => {
    this.organization = organization;
  };

  setSubscription = (subscription: Subscription | null) => {
    logger('AUTH', `New subscription: ${subscription?.name ?? null}`);

    this.subscription = subscription;
  };

  resetState = () => {
    this.setOrganization(null);
    this.setProperties([]);
    this.setSelectedProperty(null);
  };
}

const AppStore = new AppClass();

export default AppStore;
