import { useTranslation } from 'react-i18next';

import Popup from '@/components/Popup';
import StripeForm from './StripeForm';

type Props = { isOpen: boolean; onClose: () => void };

const AddStripeAccount = ({ isOpen = false, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Popup
      size="lg"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      title={t('settings.stripe.new_account_link')}
      content={<StripeForm onClose={onClose} />}
    />
  );
};

export default AddStripeAccount;
