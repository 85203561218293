import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Badge, Flex, HStack, IconButton } from '@chakra-ui/react';
import { FaExchangeAlt, FaPencilAlt } from 'react-icons/fa';
import { IoAdd, IoReload } from 'react-icons/io5';

import { changeStatusPlan, getPlans } from '@/api/subscriptions';
import DataTable from '@/components/Table';
import usePageInfo from '@/hooks/usePageInfo';
import useRequestState from '@/hooks/useRequestState';
import ActionButton from '@/components/ActionButton';
import OrganizationForm from './components/form';
import ActionBar from '@/components/ActionBar';

const SubscriptionPage = () => {
  usePageInfo({ title: 'pages.subscriptions' });

  const { t } = useTranslation();

  const [selected, setSelected] = useState<Subscription | null | undefined>(undefined);

  const { data, trigger: dataTrigger } = useRequestState<Subscription[]>(
    () => getPlans(),
    [],
  );
  const { trigger: statusTrigger } = useRequestState<'ok'>(
    (id) => changeStatusPlan(id as string),
    [],
    {
      onSuccess: dataTrigger,
      condition: false,
    },
  );

  const handleSubmit = useCallback(() => {
    dataTrigger();
  }, [dataTrigger]);

  const changeStatus = (id: Subscription['_id']) => {
    statusTrigger(id);
  };

  return (
    <>
      <Flex direction="column" h="100%" pb="20px">
        <ActionBar
          actions={
            <>
              <ActionButton mini icon={IoAdd} onClick={() => setSelected(null)} />

              <ActionButton mini icon={IoReload} onClick={dataTrigger} />
            </>
          }
        />

        <DataTable<
          Subscription,
          | 'actions'
          | 'limits.files'
          | 'limits.contracts'
          | 'limits.units'
          | 'limits.users'
          | 'limits.properties'
        >
          mt="20px"
          data={data || []}
          columns={[
            {
              id: 'name',
              header: t('subscriptions.name'),
              accessor: 'name',
              isSortable: true,
            },
            {
              id: 'isActive',
              header: t('subscriptions.status'),
              accessor: 'isActive',
              isSortable: true,
              center: true,
              cell: (data: Subscription) => (
                <Badge colorScheme={data.isActive ? 'green' : 'red'}>
                  {data.isActive ? '+' : '-'}
                </Badge>
              ),
            },
            {
              id: 'interval',
              header: t('subscriptions.interval'),
              accessor: 'interval',
              isSortable: true,
              center: true,
              cell: (data: Subscription) => (
                <Badge colorScheme={data.interval === 'month' ? 'blue' : 'purple'}>
                  {t(`subscriptions.circles.${data.interval}`)}
                </Badge>
              ),
            },
            {
              id: 'price',
              header: t('subscriptions.price'),
              accessor: 'price',
              isSortable: true,
              center: true,
            },
            {
              id: 'currency',
              header: t('subscriptions.currency'),
              accessor: 'currency',
              isSortable: true,
              center: true,
            },
            {
              id: 'discount',
              header: t('subscriptions.discount'),
              accessor: 'discount',
              isSortable: true,
              center: true,
              cell: (data: Subscription) => (
                <Badge colorScheme={data.discount > 0 ? 'green' : 'red'}>
                  {data.discount > 0 ? `${data.discount}%` : '-'}
                </Badge>
              ),
            },
            {
              id: 'limits.files',
              header: t('subscriptions.file_limit'),
              accessor: 'limits.files',
              isSortable: true,
              center: true,
            },
            {
              id: 'limits.contracts',
              header: t('subscriptions.contracts_limit'),
              accessor: 'limits.contracts',
              isSortable: true,
              center: true,
            },
            {
              id: 'limits.units',
              header: t('subscriptions.unit_limits'),
              accessor: 'limits.units',
              isSortable: true,
              center: true,
            },
            {
              id: 'limits.users',
              header: t('subscriptions.users_limit'),
              accessor: 'limits.users',
              isSortable: true,
              center: true,
            },
            {
              id: 'limits.properties',
              header: t('subscriptions.properties_limit'),
              accessor: 'limits.properties',
              isSortable: true,
              center: true,
            },
            {
              id: 'actions',
              header: t('subscriptions.actions'),
              accessor: null,
              center: true,
              cell: (data: Subscription) => (
                <HStack justify="center">
                  <IconButton
                    aria-label="Update"
                    size="sm"
                    icon={<FaPencilAlt />}
                    onClick={() => setSelected(data)}
                  />

                  <IconButton
                    aria-label="Change Status"
                    size="sm"
                    icon={<FaExchangeAlt />}
                    onClick={() => changeStatus(data._id)}
                  />
                </HStack>
              ),
            },
          ]}
        />
      </Flex>

      <OrganizationForm
        data={selected}
        setSelected={setSelected}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default observer(SubscriptionPage);
