export const getDefaultSelectedMessenger = (
  whatsapp: boolean,
  telegram: boolean,
): '-' | 'telegram' | 'whatsapp' => {
  if (whatsapp) {
    return 'whatsapp';
  }

  if (telegram) {
    return 'telegram';
  }

  return '-';
};
