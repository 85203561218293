import { SimpleGrid } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import usePageInfo from '@/hooks/usePageInfo';
import Subscriptions from './components/Subscriptions';
import StripeList from './components/StripeList';

const SettingsPage = () => {
  usePageInfo({ title: 'pages.settings' });

  return (
    <>
      <SimpleGrid
        columns={{ base: 1, lg: 1, xl: 1, '3xl': 1 }}
        gap={{ base: '20px', md: '40px' }}
      >
        <StripeList />

        <Subscriptions />
      </SimpleGrid>
    </>
  );
};

export default observer(SettingsPage);
