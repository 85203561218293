import {
  Flex,
  Input,
  Button,
  Spinner,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Link,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { registerAccountCode } from '@/api/auth';
import useRequestState from '@/hooks/useRequestState';
import { validateEmail } from '@/utils/validation';

type Props = {
  setStep: (step: LoginSteps) => void;
};

const SignUpForm = ({ setStep }: Props) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext<RegisterFormData>();

  const email = watch('email');
  const name = watch('name');

  const { trigger: sendCodeTrigger, loading } = useRequestState<'ok'>(
    () => registerAccountCode(email, name),
    [email, name],
    { condition: false, onSuccess: () => setStep('code') },
  );

  const emailIsValid = validateEmail(email);
  const nameIsValid = name?.length > 4;

  return (
    <>
      <Flex direction="column">
        <FormControl isRequired isInvalid={!!errors.name}>
          <FormLabel>{t('register.name')}</FormLabel>

          <Input
            isRequired
            fontSize="sm"
            placeholder="Example Organization"
            mb="15px"
            size="lg"
            disabled={loading}
            {...register('name')}
          />

          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.email}>
          <FormLabel>{t('register.email')}</FormLabel>

          <Input
            isRequired
            fontSize="sm"
            type="email"
            placeholder="mail@example.com"
            mb="20px"
            size="lg"
            disabled={loading}
            {...register('email')}
          />

          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <Button
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="100%"
          h="50"
          onClick={sendCodeTrigger}
          disabled={loading || !emailIsValid || !nameIsValid}
        >
          {loading ? <Spinner /> : t('login.send_code')}
        </Button>

        <Flex align="center" justify="center" mt="10px" gap="5px">
          <Text fontSize="sm">{t('register.i_agree_to')}</Text>

          <Link target="_blank" href="/privacy.html">
            {t('register.privacy_policy')}
          </Link>

          <Text fontSize="sm">{t('register.and')}</Text>

          <Link target="_blank" href="/terms.html">
            {t('register.terms_of_service')}
          </Link>
        </Flex>
      </Flex>
    </>
  );
};

export default SignUpForm;
