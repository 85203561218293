import { Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

const AuthLayout = () => {
  return (
    <Flex justifyContent="center" h="100%">
      <Flex
        w="100%"
        maxW={{ base: '100%', md: 800 }}
        justify="center"
        align="center"
        px={{ base: '25px', md: '0px' }}
        flexDirection="column"
      >
        <Outlet />
      </Flex>
    </Flex>
  );
};

export default AuthLayout;
